import { Divider } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import Header from '../Components/Header/Header';
import TextCard from '../Components/TextCard/TextCard';
import classes from './AboutMe.module.css'
import './Pages.css'

const ConsultingOther = () => {

    const { t } = useTranslation('translation', { keyPrefix: 'Counselling_Other' });
    const { i18n } = useTranslation();

    return (
        <div className={classes.AboutMe}>
            <Header />
            <div className={i18n.language === "ar" ? classes.ArabicRtl : ""}>
                <div className={classes.title}>
                    <h1>{t("counselling_Other_highlight")}</h1>
                </div>
                <div className={classes.TextSpace}>
                    <div className={classes.AboutMe_Text}>

                        <TextCard>{t("counselling_Other")}</TextCard>

                        <Divider orientation="left"><h2>{t("realestate_highlight")}</h2></Divider>
                        <TextCard>{t("realestate")}</TextCard>

                        <Divider orientation="left"><h2>{t("translation_service_highlight")}</h2></Divider>
                        <TextCard>{t("translation_service")}</TextCard>

                        <Divider orientation="left"><h2>{t("industrial_consultancy_highlight")}</h2></Divider>
                        <h3 className='sub_title'>{t("import_export_consultancy_highlight")}</h3>
                        <TextCard>{t("import_export_consultancy")}</TextCard>

                        <h3 className='sub_title'>{t("tourism_consultancy_highlight")}</h3>
                        <TextCard>{t("tourism_consultancy")}</TextCard>

                        <h3 className='sub_title'>{t("education_foundation_highlight")}</h3>
                        <TextCard>{t("education_foundation")}</TextCard>

                        <Divider orientation="left"><h2>{t("personal_permission_highlight")}</h2></Divider>
                        <h3 className='sub_title'>{t("residince_permission_highlight")}</h3>
                        <TextCard>{t("residince_permission")}</TextCard>

                        <h3 className='sub_title'>{t("working_permission_highlight")}</h3>
                        <TextCard>{t("working_permission")}</TextCard>

                        <h3 className='sub_title'>{t("citizenship_acquiring_highlight")}</h3>
                        <TextCard>{t("citizenship_acquiring")}</TextCard>

                        <Divider orientation="left"><h2>{t("rental_agreement_highlight")}</h2></Divider>
                        <h3 className='sub_title'>{t("home_rent_contracts_highlight")}</h3>
                        <TextCard>{t("home_rent_contracts")}</TextCard>

                        <h3 className='sub_title'>{t("office_rent_highlight")}</h3>
                        <TextCard>{t("office_rent")}</TextCard>

                        <h3 className='sub_title'>{t("home_office_highlight")}</h3>
                        <TextCard>{t("home_office")}</TextCard>

                        <h3 className='sub_title'>{t("virtual_office_highlight")}</h3>
                        <TextCard>{t("virtual_office")}</TextCard>

                        <h3 className='sub_title'>{t("physical_office_highlight")}</h3>
                        <TextCard>{t("physical_office")}</TextCard>

                        <Divider orientation="left"><h2>{t("company_infrastructure_highlight")}</h2></Divider>
                        <h3 className='sub_title'>{t("company_introduction_highlight")}</h3>
                        <TextCard>{t("company_introduction")}</TextCard>

                        <h3 className='sub_title'>{t("company_documentiation_highlight")}</h3>
                        <TextCard>{t("company_documentiation")}</TextCard>

                        <h3 className='sub_title'>{t("quality_management_highlight")}</h3>
                        <TextCard>{t("quality_management")}</TextCard>


                        <Divider orientation="left"><h2>{t("import_export_highlight")}</h2></Divider>
                        <TextCard>{t("import_export_adviser")}</TextCard>
                        <h3 className='sub_title'>{t("operational_processes_title")}</h3>
                        <TextCard>{t("operational_processes_text")}</TextCard>

                        <h3 className='sub_title'>{t("selling_processes_title")}</h3>
                        <TextCard>{t("selling_processes_text")}</TextCard>

                        <h3 className='sub_title'>{t("goverment_processes_title")}</h3>
                        <TextCard>{t("goverment_processes_text")}</TextCard>

                        <h3 className='sub_title'>{t("fuar_processes_title")}</h3>
                        <TextCard>{t("fuar_processes_text")}</TextCard>

                        <h3 className='sub_title'>{t("distributor_processes_title")}</h3>
                        <TextCard>{t("distributor_processes_text")}</TextCard>

                        <h3 className='sub_title'>{t("solution_processes_title")}</h3>
                        <TextCard>{t("solution_processes_text")}</TextCard>

                    </div>
                </div>
            </div>
            <Footer style={{ textAlign: 'center', bottom: 0, width: "100%", backgroundColor: "black", color: "white" }}></Footer>
        </div>);
}

export default ConsultingOther;