const TRANSLATION_TR = {
    About_Us: {
        about_us_title: "Hakkımızda",
        about_us: "ŞİN Mali Müşavirlik kurulduğu günden bugüne sadece Türkiye'ye gelen yabancı yatırımcılara hizmet veren bir Mali Müşavirlik şirketidir. \r\nMükelleflerimizin tamamı yabancı uyrukludur.",
        our_mission_title: "Misyonumuz",
        our_mission: "Ülkemize geldikleri andan itibaren yabancı mükelleflerimizin yanlarında olmak, \r\nÇalışmayı planladıkları sektöre uygun şirket kuruluşunu sağlamak, \r\nOnları iş dünyasındaki gelişmelerden sürekli olarak haberdar etmek, \r\nKurdukları şirketlerin Devlet teşviklerinden istifade etmesini sağlamak, \r\nOnlarla kendi dillerinde kurduğumuz iletişim ile, iş hayatının her alanında yanlarında olarak kendilerini ülkelerinde gibi hissettirmektir.",
        our_mission_highlight: " ŞİN Mali Müşavirlik olarak mükelleflerimize en geniş kapsamlı hizmet sunmak misyonumuzun temelidir.",
        our_vission_title: "Vizyonumuz",
        our_vission: "Şirketlere ŞİN (Şirket İşletim Normları) Kalite Yönetim Sistemini kurmak yoluyla; \r\n\r\nİş yerinde sistem ve disiplin oluşturarak çalışma ortamında huzuru sağlamak, \r\nKendini yaptığı işin mükemmeliyetine adayan çalışanlarla insana değer katmak, \r\nOptimum maliyetlerle çalışmak suretiyle şirketin varlıklarını korumak, \r\nŞirketin piyasa etkinliğini arttırarak ekonomiye değer katan bir yapıya ulaşmasını sağlamaktır.",
        our_vission_higlight: "ŞİN Mali Müşavirlik ile çalışmak mükellefleri için üçüncü kişilere karşı önemli bir referanstır.",
        our_value_title: "Değerlerimiz",
        our_value: "Hizmetlerimizde profesyonellik, \r\nGüncel mevzuat takibinde dinamiklik, \r\nMükelleflerimiz ile olan ilişkilerimizde gizlilik, \r\nMesleki etik kurallarına olan sadakatimiz, \r\nŞİN Mali Müşavirlik şirketinin omurgasını ve kalite politikasını oluşturur.",
        our_value_highlight: "Çünkü biliyoruz ki bize değer kazandıran şeyler, yaptığımız işlerdir."
    },

    Counselling: {
        councelling_title: "ŞİN MALİ MÜŞAVİRLİK HİZMETLERİ",
        counselling: "ŞİN Mali Müşavirlik kurulduğu günden bugüne sadece Türkiye'ye gelen yabancı yatırımcılara hizmet vermek üzere kurulmuş bir Mali Müşavirlik şirketidir. \r\nDolayısıyla mükelleflerimize 10 yılı aşkın birikim ve tecrübelerimizle hizmet vermekteyiz. \r\nŞİN Mali Müşavirlik olarak yabancı mükelleflerimize Muhasebe alanında aşağıdaki konu başlıklarında yardımcı olmaktayız.",
        counselling_highlight: "Şirket kuruluş işlemleri",
        company_creating: "Yabancı yatırımcılara Türkiye pazarına girişte mevzuat desteği sağlamak, \r\nYabancı yatırımcıları ilgilendikleri sektör hakkında bilgilendirmek, \r\nÇalışmak istedikleri sektörün gereklerine uygun şirket kurmalarını sağlamak,",
        company_selling_highlight: "Şirket satın alma hizmetleri",
        company_selling: "Yabancı mükellefimizin yerli bir şirketi satın almak istemesi halinde, yabancı mükelleflerin konu sektörde şirket sahibi olmalarına mani olup olmadığını araştırmak, \r\nMani olmaması halinde, almak istedikleri şirketin geçmişini araştırmak, toplam borc ve alacaklarını çıkarmak, şirketin hukuki durumunu kanunlar çerçevesinde incelemek ve raporlamak,",
        company_partnering_highlight: "Ortak olma & ortaklıktan ayrılma hizmetleri ",
        company_partnering: "Yabancı mükellefimizin yerli bir şirkete ortak olmak istemesi halinde; şirketin mali, hukuki ve finansal durumunu inceleyip raporlamak ve ortaklık işlemlerini takip ederek sonuçlandırmak, \r\nYabancı mükellefimizin şirket ortaklığından ayrılmak istemesi halinde şirket ortaklığından ayrılmak için gerekli prosedürlerin uygulandığından emin olmak ve bu işlemleri takip ederek sonuçlandırmak,",
        financial_adviser_highlight: "Mali danışmanlık",
        financial_adviser: "Mali konularda  (Vergi, SGK, Teşvik, İstisna, Muafiyet) müşavirlik yapmak, \r\nŞirketlerde kuruluş, birleşme, devir ve fesih işlemlerini icra etmek, \r\nŞirketlerde nevi değişiklikleri, sermaye artırma ve azaltma işlemlerini yapmak, \r\nYatırım danışmanlığı çerçevesinde fizibilite raporları hazırlamak,",
        accounting_adviser_highlight: "Muhasebe danışmanlığı",
        accounting_adviser: "Vergi Usul Kanunlarına göre şirketlerin defterlerini tutmak, \r\nYasal kayıtların, muhasebe içi işlemlerin doğru, tam ve zamanında yapılmasını sağlamak, \r\nMükelleflerimizi Sosyal Güvenlik Mevzuatı konularında bilgilendirmek, \r\nİş Kanunları çerçevesinde beyannamelerin hazırlanıp resmi mercilere teslim etmek, \r\nVergi Kanunları çerçevesinde şirketlerin beyannamelerini tanzim ve tasdik etmek,",
        finance_adviser_highlight: "Finans danışmanlığı",
        finance_adviser: "İthalat ve ihracat prefinansmanları \r\nİhracat alacaklarının teminat altına alınması Eximbank kredi sigortası, Factoring, Forfaiting \r\nForward, Swap, Future contracts, Option enstrümanlarıyla risk yönetimi \r\nBanka avalleri ve teminat mektuplarının edinilmesi ve kullanılması \r\nYatırım teşviklerinden faydalanma ve borçlanma tercihlerinin belirlenmesi \r\nGelir-Gider tabloları hazırlamak ",
        goverment_incentive_adviser_highlight: "Devlet teşvikleri danışmanlığı",
        goverment_incentive_adviser: "Genel yatırım teşvik uygulamaları hakkında bilgi sunmak \r\nBölgesel yatırım teşvik uygulamaları hakkında bilgi sunmak \r\nBüyük ölçekli yatırım teşvik uygulamaları hakkında bilgi sunmak \r\nStratejik yatırım teşvik uygulamaları hakkında bilgi sunmak",
        governance_adviser_highlight: "Denetim danışmanlığı",
        governance_adviser: "Genel Kabul Görmüş Muhasebe İlkeleri’ ve ‘Vergi Mevzuatı’ çerçevesinde \r\nŞirketlerin S.W.O.T analizlerini yapmak \r\nİç kontrol sistemini oluşturmak \r\nFaaliyet ve Uygunluk denetimlerini yapmak \r\nKurum örgütsel yapısını, iç kontrol sistemleri, iş akışları ve uygulamaları incelemek \r\nDepartmanların yapabilirliğini ve çalışanların mesleki yeterliliğini ölçmek \r\nŞirket daimi ve cari dosyaları oluşturmak",

    },
    Counselling_Other: {
        counselling_Other_highlight: "DİĞER HİZMETLERİMİZ",
        counselling_Other: "Bir yabancı yatırımcının Türkiye’ye geldiği andan itibaren nelere ihtiyaç duyabileceğini biliyor ve kendisine bu konularda gerekli desteği sağlıyoruz.",
        realestate_highlight: "Gayrimenkul Danışmanlık Hizmetleri",
        realestate: "Yabancıların gerek vatandaşlık elde etme gerekse yatırım amaçlı gayrimenkul (ev, işyeri, ofis, arsa, fabrika…vs) edinimlere yardımcı olmak. \r\nKendilerine aradıkları özelliklerde gayrimenkulün bulunması, \r\nKendilerine aradıkları özelliklerde gayrimenkulün bulunması, \r\nTapunun belgesinin üzerindeki bilgilerin kontrolü, Gayrimenkul sahipliğinin (kişi ya da şirket) itilaflı olup olmadığının kontrolü, \r\nGayrimenkul üzerinde herhangi bir haciz, ipotek ya da mahkeme süreci olup olmadığının kontrolü \r\nGayrimenkulün Belediyeden iskanının olup olmadığının kontrolü, \r\nGayrimenkul alımında ödenecek vergi ve harçların varsa emlakçı komisyon oranlarının kontrolü, \r\nSatın alma işleminin kanunlar çerçevesinde yürütülmesi, \r\nYabancıların gayrimenkul edinimi yoluyla vatandaşlık işlemlerinin takibi, \r\nSözleşmeli alımlarda alım-satım sözleşmesinin düzenlenmesi ya da mevcut sözleşmenin incelenmesi,",
        translation_service_highlight: "Dil tercüme hizmetleri",
        translation_service: "Mali Müşavir Mesut ŞİN; İngilizce, Fransızca, Almanca ve Arapça dillerinde yeminli tercümanlık ruhsatına sahip olup tercüme hizmetlerini sadece mükelleflerine vermektedir.",
        industrial_consultancy_highlight: "Sektörel danışmanlıklar",
        import_export_consultancy_highlight: "İhracat-İthalat danışmanlığı",
        import_export_consultancy: "Mali Müşavirin yıllarca çalıştığı sektör olduğundan mükelleflerine dış ticaretin tüm alanları olan Mevzuat, Operasyonel işlemler ve Pazarlama konularında destek sağlıyoruz.",
        tourism_consultancy_highlight: "Turizm danışmanlığı",
        tourism_consultancy: "Turizm sektöründe Seyahat Acentası olarak faaliyet gösterecek şirketlerin altyapısını oluşturmak ve onların A, B, C sınıfı turizm seyahat acentesi TURSAB belgesini edinmesini sağlıyoruz.",
        education_foundation_highlight: "Eğitim kurumları danışmanlığı",
        education_foundation: "Eğitim alanında faaliyet göstermek isteyen şirketlere gerekli izinlerin alıyoruz.",
        personal_permission_highlight: "Kişisel izinler",
        residince_permission_highlight: "Oturma izni edinimi",
        residince_permission: "Mükelleflerimizin Türkiye'de kurdukları şirketler üzerinden oturma izni almalarını sağlamak,",
        working_permission_highlight: "Çalışma izni edinimi",
        working_permission: "Mükelleflerimizin ve/veya yabancı çalışanlarının çalışma izni almalarını sağlamak,",
        citizenship_acquiring_highlight: "Vatandaşlık edinimi",
        citizenship_acquiring: "Gayrimenkul alımı, sermaye aktarımı veya büyük ölçekli yatırım yapmak yoluyla vatandaş olmak isteyen yabancıların vatandaşlık işlemlerini takip edip sonuçlandırmak,",
        rental_agreement_highlight: "Kira kontratları destek hizmetleri",
        home_rent_contracts_highlight: "Ev kiralama hizmetleri",
        home_rent_contracts: "Mükelleflerimizin haklarını korumak için ikamet amaçlı kiraladıkları garimenkullerin kira kontratlarını dört dilde hazırlamak, hazır kontratları incelemek, tercüme etmek ve Noterde onaylatmak,",
        office_rent_highlight: "İşyeri kiralama hizmetleri",
        office_rent: "Mükelleflerimizin haklarını korumak için işyeri amaçlı kiraladıkları gayrimenkul kira kontratları dört dilde hazırlamak, hazır kontratları incelemek, tercüme etmek ve Noterde onaylatmak,",
        home_office_highlight: "Home Ofis hizmetleri",
        home_office: "Mükelleflerimize yapacakları işin muhteviyatına göre ve çalıştıkları sektöre göre home ofis uygulamasını anlatıp gerekli desteği sağlamak,",
        virtual_office_highlight: "Sanal Ofis kiralama hizmetleri",
        virtual_office: "Mükelleflerimize çalıştıkları sektöre göre en uygun sanal ofis hizmeti veren sanal ofis firmalarını tespit edip en uygun tercihi yapmalarını sağlamak,",
        physical_office_highlight: "Fiziki Ofis kiralama hizmetleri",
        physical_office: "Mükelleflerimize çalıştıkları sektöre göre en uygun lokasyonu tespit edip işyeri kiralama işlemini; AVM, Site Yönetimi gibi kurumlar aracılığı ile yapmak,",
        company_infrastructure_highlight: "Şirket altyapı hizmetleri",
        company_introduction_highlight: "Şirketlerin tanıtım hizmetleri",
        company_introduction: "Şirketin tanıtım altyapısını hazırlamak amacıyla; web sitesi, şirket tanıtım maili, referans mektubu hazırlamak, arama motoru kaydı, youtube kanalı ve sosyal medya adresleri oluşturmak, ",
        company_documentiation_highlight: "Şirket promosyon altyapısı hizmetleri",
        company_documentiation: "Kartvizit, Broşür, Logo, Kaşe, Antetli kağıt,  şirket elektronik imzası oluşturmak,",
        quality_management_highlight: "ŞİN Kalite Yönetim Sistemi",
        quality_management: "ŞİN (Şirket İşletim Normları) adı altında kalite yönetim sistemi kurmak suretiyle çalışanların ve departmanların yapabilirliğini ölçmek, görev tanım tablosu, iş akış şeması ve vekalet sistemini oluşturmak,",
        
        import_export_highlight: "İhracat & İthalat danışmanlığı",
        import_export_adviser: "Mali Müşavirimiz dış ticaret konusunda çok sayıda seminer vermiş olup, şirket sahiplerine ve çalışanlarına bu alanda özel eğitimler vermektedir. \r\nYurtdışı ticaret ile ilgili hakim olunması gereken 4 alan; mesleki bilgi, yabancı dil, pazarlama, operasyonel işlemlerdir.",
        operational_processes_title: "Operasyonel işlemler",
        operational_processes_text: "Dış ticarette iş akış şemasının oluşturulması, resmi vesaik altyapısının oluşturulması, teslim ve ödeme şekillerinin belirlenmesi",
        selling_processes_title: "Pazarlama",
        selling_processes_text: "Ürünün reklam ve satış altyapısının oluşturulması. Masa başı ve alan araştırmaları altyapısının hazırlanması",
        goverment_processes_title: "Devlet Teşvikleri",
        goverment_processes_text: "Yurtdışı işyeri, mağaza, ofis depo açma destekleri, reklam ve tanıtım desteği, Pazar araştırmaları desteği, marka tescil desteği ve Turquality",
        fuar_processes_title: "Fuar destekleri",
        fuar_processes_text: "Bireysel ve Milli katılımlı reel fuarlar ve sanal fuarlar için devlet teşvikleri",
        distributor_processes_title: "Acente ve Distributörlük anlaşmaları",
        distributor_processes_text: "Konu sektörün yapısı gereği hedef pazarda direkt satış veya kullanılması gereken dağıtım araçları olan acente, distributor seçimlerini tahlil edip gerekli anlaşmanın hazırlanması ve/veya hazır anlaşmaların incelenip mükelleflerimizin koruma altına alınması.",
        solution_processes_title: "Çözüm Ortaklarımız",
        solution_processes_text: "Dış Ticaret işlemlerinizin sorunsuz şekilde yapılabilmesi için gerekli olan Gümrükçüler ve Nakliyeciler ile olan bağlantılarımızdan faydalanabilirsiniz.",

        
    },
    About_Me: {
        university: "Üniversite (1996 - 2000) ",
        university_data: "Uludağ Üniversitesi İktisadi İdari Bilimler Fakültesi ekonomi bölümü mezunudur.",
        foregin_languages: "Yabancı Diller (2000 - 2003)",
        foregin_languages_data: "Yabancı dillere olan ilgisi nedeniyle, mastırını üniversite sonrası gittiği Kanada’nın Montreal bulunan Education Canada College üniversitesinde 'İngilizce Öğretmenliği' üzerine tamamlayıp \r\nTESOL (Teacher of English to Speakers of Other Languages) diplomasını aldı. \r\nAkabinde Fransızca eğitimini yine aynı üniversitede CF (Competence en Français) diplomasını alarak tamamladı. \r\nAlmanca ve Arapça da bilen Mesut Şin, aynı zamanda İstanbul Dil Kulübü’nün kurucusudur. \r\n\r\nMesut ŞİN; İngilizce, Fransızca, Almanca ve Arapça dillerinde yeminli tercümanlık ruhsatına sahiptir.",
        external_export: "Dış Ticaret (2003 - 2010)",
        external_export_data: "Yurtdışı eğitimini tamamlayıp İstanbul’a döndükten sonra bir tekstil firmasında dış ticaret sorumlusu olarak çalışmaya başladı. \r\nİş yoğunluğu ve destekleyici eğitimler sayesinde dış ticaret alanında ilerledi. Sektöre özel ilgisi nedeniyle ihracat ve ithalatın her alanında (Mevzuat, Pazarlama ve Operasyonel işlemlerde) uzmanlaştı. \r\nDış Ticarette; akreditifler, devlet teşvikleri, masabaşı ve alan araştırmaları, fuar teşvik ve hazırlıkları, dış ticarette Acente ve Distribütörlük sözleşmeleri danışmanlık yaptığı konular arasındadır. \r\nAyrıca, çalıştığı şirketlerde ISO-9001 Kalite Yönetim Sistemi ki daha sonra daha sade bir kalite sistemi olan ŞİN (Şirket İşletim Normları) sistemleri kurmak suretiyle şirketlerin performans arttırıcı sistemlere kavuşmasına yardımcı oldu."
    },
    Refrences: {
        belgium: "Belçika",
        egypt: "Mısır",
        canada: "Kanada",
        saudi: "Suudi Arabistan",
        sweden: "İsveç",
        algerie: "Cezayir",
        cote: "Fildişi Sahilleri",
        us: "ABD",
        sengal: "Sengal",
        palestinian: "Filistin",
        sudan: "Sudan",
        bulgaria: "Bulgaristan"
    },
    Bio: {
        title: "MESUT SIN",
        university_title: "Üniversite (1996 - 2000) ",
        university: "Uludağ Üniversitesi İktisadi İdari Bilimler Fakültesi ekonomi bölümü mezunudur.",
        foregin_languages_title: "Master (2000 - 2003)",
        foregin_languages: "Yabancı dillere olan ilgisi nedeniyle, mastırını üniversite sonrası gittiği Kanada’nın Montreal bulunan Education Canada College üniversitesinde 'İngilizce Öğretmenliği' üzerine tamamlayıp, TESOL (Teacher of English to Speakers of Other Languages) diplomasını aldı.\r\nAkabinde Fransızca eğitimini yine aynı üniversitede CF (Competence en Français) diplomasını alarak tamamladı.\r\nAlmanca ve Arapça da bilen Mesut Şin, aynı zamanda İstanbul Dil Kulübü’nün kurucusudur.\r\n\r\nMesut ŞİN; İngilizce, Fransızca, Almanca ve Arapça dillerinde yeminli tercümanlık ruhsatına sahiptir.",
        foregin_trade_title: "Dış Ticaret (2003 - 2010)",
        foregin_trade: "Yurtdışı eğitimini tamamlayıp İstanbul’a döndükten sonra bir tekstil firmasında dış ticaret sorumlusu olarak çalışmaya başladı.\r\nİş yoğunluğu ve destekleyici eğitimler sayesinde dış ticaret alanında ilerledi. Sektöre özel ilgisi nedeniyle ihracat ve ithalatın her alanında (Mevzuat, Pazarlama ve Operasyonel işlemlerde) uzmanlaştı.\r\nDış Ticarette; akreditifler, devlet teşvikleri, masabaşı ve alan araştırmaları, fuar teşvik ve hazırlıkları, dış ticarette Acente ve Distribütörlük sözleşmeleri danışmanlık yaptığı konular arasındadır.\r\n\r\nAyrıca, çalıştığı şirketlerde ISO-9001 Kalite Yönetim Sistemi ki daha sonra daha sade bir kalite sistemi olan ŞİN (Şirket İşletim Normları) sistemleri kurmak suretiyle şirketlerin performans arttırıcı sistemlere kavuşmasına yardımcı oldu.",
        accountancy_title: "Mali Müşavirlik (2010 -  )",
        accountancy: "2010 yılında Mali Müşavirlik bürosunu açtı. Kuruluş aşamasında müşteri pörtföyü olarak ihracat yapmak isteyen yerli firmaları hedeflerken,\r\nkendiliğinden gelişen koşullar ve yurtdışından gelen talepler sonrası ülkemize gelen yabancı mükelleflere hizmet vermeye başladı.\r\nYabancı dilde hizmet veren Muhasebe bürosu eksikliği nedeniyle sektörün bu alandaki boşluğunu doldurmak için sadece yabancı yatırımcılara hizmet vermeye başladı.",
        istanbul_title: "Istanbul",
        istanbul: "Mesut SIN, who is an Istanbul lover, supports all the works about Istanbul with the philosophy that 'Istanbul is the center of the world'.\r\nIt's our duty to add value to Istanbul, as well as to add value to the companies of our clients.",
        certificates: "Diplomalar / Lisanslar / Sertifikalar"
    },
    library:{
        first_text: "Mali Muşavir Mesut SIN döviz kurlarındaki oynaklığı açıklıyor\r\n\r\nMaster Mesut SIN explains about the volatility in exchange rates\r\n\r\nMaître Mesut SIN s'explique la volatilité des taux de change\r\n\r\nMesut SIN erklärt die Volatilität der Wechselkurse\r\n\r\nالتقلبات في أسعار الصرف Mesut SIN يشرح  "
    },
    Contact:{
        contact_title: "İletisim",
        phone_number: "Telefon Numarası",
        address: "Adres"
    },
    Headers: {
        main_page: "Ana Sayfa",
        about_us: "Hakkımızda",
        counselling: "Danışmanlıklarımız",
        counselling_other: "Diğer Hizmetler",
        reffrences: "Referanslar",
        library: "Kütüphane",
        activities: "Seminerler",
        contac_us: "Bize Ulaşın"
    }

}

export default TRANSLATION_TR;