import { Col, Divider, Image, Row } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useTranslation } from "react-i18next";
import Header from "../Components/Header/Header";
import TextCard from "../Components/TextCard/TextCard";
import classes from './AboutMe.module.css'
import image from "../Assets/Images/Mesut_SIN.JPG"

import './Pages.css'
import useWindowSize from "../useWindowSize";

function Bio() {

    const { t } = useTranslation('translation', { keyPrefix: 'Bio' });
    const { i18n } = useTranslation();
    const size = useWindowSize()
    const certificate_width = 120

    return (
        <div className={classes.AboutMe}>
            <Header />

            <div className={i18n.language === "ar" ? classes.ArabicRtl : ""}>

                <div className={classes.title}>
                    <h1>{t("title")}</h1>
                </div>

                <div className={classes.TextSpace}>

                    <div className={classes.AboutMe_Text} id="arabic">

                        {size.width <= 1000 && <div className="bio_image_container"><img src={image} /></div>}

                        <div className="bio_page">
                            <div>
                                <Divider orientation="left" orientationMargin="0"><h2>{t("university_title")}</h2></Divider>
                                <TextCard >{t("university")}</TextCard>

                                <Divider className="divider" orientation="left" orientationMargin="0"><h2>{t("foregin_languages_title")}</h2></Divider>
                                <TextCard>{t("foregin_languages")}</TextCard>

                                <Divider className="divider" orientation="left" orientationMargin="0"><h2>{t("foregin_trade_title")}</h2></Divider>
                                <TextCard>{t("foregin_trade")}</TextCard>

                                <Divider className="divider" orientation="left" orientationMargin="100"><h2>{t("accountancy_title")}</h2></Divider>
                                <TextCard>{t("accountancy")}</TextCard>

                            </div>
                            {size.width > 1000 && <div>
                                <div className="bio_image_container"><img src={image} /></div>
                            </div>
                            }
                        </div>

                        {size.width <= 1000 && <div className="bio_page">
                        </div>
                        }
                    </div>
                </div>
            </div>
            <Footer style={{ textAlign: 'center', bottom: 0, width: "100%", backgroundColor: "black", color: "white" }}></Footer>
        </div>);
}

export default Bio;