import classes from "./ImageCard.module.css"
import "flag-icons/css/flag-icons.css"

function ImageCard(props) {

    const {name,src,flag, country_name} = props;
    
    return (
        <div className={classes.ImageCard}>
            <p>{name}</p>
            <div>
                <img className = {classes.company_logo} src={src} alt = "Company Logo"/>
            </div>
            <p>{country_name}</p>
            <div>
            <div class={`fi fi-${flag}`} 
            style={{  display: "flex",
                    height: "115px",
                    width: "auto",
                    "marginBottom": "10px"}}/>
            </div>
        </div>
    );
}

export default ImageCard;