import { Divider } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import Header from '../Components/Header/Header';
import TextCard from '../Components/TextCard/TextCard';
import classes from './AboutMe.module.css'
import './Pages.css'

function Contact() {

    const { t } = useTranslation('translation', { keyPrefix: 'Contact' });
    const { i18n } = useTranslation();

    return (
        <div className={classes.AboutMe}>
            <Header />

            <div className={i18n.language === "ar" ? classes.ArabicRtl : ""}>
            <div className={classes.TextSpace}>
                <div className={classes.AboutMe_Text} id="arabic">


                    <Divider orientation="left" orientationMargin="0"><h2>{t("contact_title")}</h2></Divider>
                    <TextCard>{t("phone_number")} / Whatsapp: +90 (537) 842 08 06</TextCard>
                    <TextCard>E-Mail: sharer100@hotmail.com</TextCard>
                    <TextCard>LinkedIn: <a href = "https://www.facebook.com/mesut.sin.12">Mesut Sin</a></TextCard>
                    <TextCard>Facebook: <a href = "https://www.linkedin.com/in/mesut-sin-5a51b873/">Sin Mali Musavirlik</a></TextCard>
                    <TextCard>{t("address")}: Mushine Hatun Mah. Büyük Kömürcü Sok. No: 13 Daire: 1, Kumkapı - Fatih/İstanbul </TextCard>
                    
                </div>
            </div>
            </div>
            <Footer style={{ textAlign: 'center', bottom: 0, width: "100%", backgroundColor: "black", color: "white" }}></Footer>
        </div>);
}

export default Contact;