import { Col, Divider, Row } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../Components/Header/Header";
import TextCard from "../Components/TextCard/TextCard";
import useWindowSize from "../useWindowSize";
import classes from './AboutMe.module.css'


import './Pages.css'

function Library() {

    const { t } = useTranslation('translation', { keyPrefix: 'library' });
    const { i18n } = useTranslation();
    const [colSpan, setColSpan] = useState(8)
    const [mobileMode, setMobileMode] = useState(false)
    const size = useWindowSize()

    const text1 = "Mali Müşavir Mesut SIN döviz kurlarındaki oynaklığı açıklıyor\r\n\r\nMaster Mesut SIN explains about the volatility in exchange rates\r\n\r\nMaître Mesut SIN s'explique la volatilité des taux de change\r\n\r\nMesut SIN erklärt die Volatilität der Wechselkurse\r\n\r\nالتقلبات في أسعار الصرف Mesut SIN يشرح  "

    useEffect(() => {
        console.log(size)
        if (size.width <= 690) {
            setColSpan(24)
            setMobileMode(true)
        }
        else {
            setColSpan(12)
            setMobileMode(false)
        }
    }, [size])

    return (
        <div className={classes.AboutMe}>
            <Header />
            <div className={i18n.language === "ar" ? classes.ArabicRtl : ""}>
            <div className={classes.title}>
                <h1>Kütüphane</h1>
            </div>
            <div className="TextSpace_Wider Library">
                <div className={classes.AboutMe_Text} id="arabic">
                    <Divider orientation="left" orientationMargin="0"><h2>Döviz kurları / Exchange rates /  Taux de change / Wechselkurse / أسعار الصرف</h2></Divider>
                    <Row align="middle">
                        <Col span={colSpan} order={mobileMode ? 1 : 2}>
                            <div className="library_video__container">
                                <iframe className="library_video" src="https://www.youtube.com/embed/m1PzkA4usFI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen />
                            </div>
                        </Col>
                        <Col span={colSpan} order={mobileMode ? 2 : 1}>
                            <div className="bio_page">
                                <div>
                                    (16.12.2021)
                                    <TextCard >{text1}
                                    </TextCard>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Divider />
            </div>
            </div>
            <Footer style={{ textAlign: 'center', bottom: 0, width: "100%", backgroundColor: "black", color: "white" }}></Footer>
        </div>);
}

export default Library;