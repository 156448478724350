import logo from "../../Assets/logo/site_logo.png"
import small_logo from "../../Assets/logo/small_logo.png"
import style from "./Header.module.css"
import { TwitterOutlined, YoutubeFilled, MenuFoldOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../useWindowSize";
import { Menu } from "antd";
import { Button } from "antd";
import { Popover } from "antd";

const { Option } = Select;


const Header = () => {

    const { i18n } = useTranslation();
    const size = useWindowSize();

    const { t } = useTranslation('translation', { keyPrefix: 'Headers' });

    function handleLanguageChange(value) {
        localStorage.setItem("selected_lang", value)
        i18n.changeLanguage(value);
    }

    const items = <Menu>
        <Link to="/home" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>{t("main_page")}</Menu.Item></Link>
        <Link to="/about" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>{t("about_us")}</Menu.Item></Link>
        <Link to="/consulting" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>{t("counselling")}</Menu.Item></Link>
        <Link to="/consulting_other" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>{t("counselling_other")}</Menu.Item></Link>
        <Link to="/refrences" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>{t("reffrences")}</Menu.Item></Link>
        <Link to="/library" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>{t("library")}</Menu.Item></Link>
        <Link to="/seminars" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>{t("activities")}</Menu.Item></Link>
        <Link to="/bio" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>Mesut Şin</Menu.Item></Link>
        <Link to="/contact" style={{ color: 'inherit', textDecoration: 'inherit' }}><Menu.Item>{t("contac_us")}</Menu.Item></Link>     
    </Menu>;



    return (
        <div className={style.header}>
            <div className={style.header__top}>
                <div>
                    <TwitterOutlined style={{ fontSize: '30px', color: 'grey' }} className={style.icon} />
                    <YoutubeFilled style={{ fontSize: '30px', color: 'grey' }} onClick={() => { window.location.href = "https://www.youtube.com/channel/UCMa7LQchv0vQmbZyP5ZsnMQ" }} className={style.icon} />
                </div>
                <Select style={{ width: 120, marginRight: "1rem" }} defaultValue={i18n.language} onChange={handleLanguageChange}>
                    <Option value="tr">Türkçe</Option>
                    <Option value="en">English</Option>
                    <Option value="fr">Français</Option>
                    <Option value="de">Deutsch</Option>
                    <Option value="ar">عربى</Option>
                </Select>
            </div>
            <div className={style.header__main}>
                <img className={style.header__img} src={size.width < 700 ?  small_logo : logo} alt= "SIN Mali Musavirlik Logo"/>
                {size.width > 1200 && //{t("contac_us")}
                    <ul style={i18n.language === "ar" ? {justifyContent: "flex-start", direction: "rtl"} : {}}>
                        <li><Link to="/home" style={{ color: 'inherit', textDecoration: 'inherit' }}>{t("main_page")} </Link></li>
                        <li><Link to="/about" style={{ color: 'inherit', textDecoration: 'inherit' }}>{t("about_us")} </Link></li>
                        <li><Link to="/consulting" style={{ color: 'inherit', textDecoration: 'inherit' }}>{t("counselling")}</Link></li>
                        <li><Link to="/consulting_other" style={{ color: 'inherit', textDecoration: 'inherit' }}>{t("counselling_other")}</Link></li>
                        <li><Link to="/refrences" style={{ color: 'inherit', textDecoration: 'inherit' }}>{t("reffrences")}</Link></li>
                        <li><Link to="/library" style={{ color: 'inherit', textDecoration: 'inherit' }}>{t("library")}</Link></li>
                        <li><Link to="/seminars" style={{ color: 'inherit', textDecoration: 'inherit' }}>{t("activities")}</Link></li>
                        <li><Link to="/bio" style={{ color: 'inherit', textDecoration: 'inherit' }}>Mesut Şin</Link></li>
                        <li><Link to="/contact" style={{ color: 'inherit', textDecoration: 'inherit' }}>{t("contac_us")}</Link></li>
                    </ul>}

                {size.width <= 1200 &&
                    <ul>
                        <li>
                            <Popover placement="topRight" content={items} trigger="click">
                                <Button icon={<MenuFoldOutlined />} size={size} />
                            </Popover>

                            {/*  
                            <Dropdown overlay={items} trigger={['click']}>
                            <Button icon={<MenuFoldOutlined />} size={size} />
                            </Dropdown>*/}
                        </li>
                    </ul>}

            </div>



        </div>
    );
}

export default Header;