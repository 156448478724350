const TRANSLATION_DE = {
    About_Us: {
        about_us_title: "ÜBER UNS",
        about_us: "ŞİN Mali Musavirlik ist ein Finanzberaterunt, das seit seiner Gründung nur mit ausländischen Investoren arbeitet, die in die Türkei kommen. \r\nAlle unsere Kunden sind ausländischer Herkunft.",
        our_mission_title: "Unser Auftrag",
        our_mission: "Unseren Kunden von dem Moment an, in dem sie in die Türkei kommen, zur Seite zu stehen, \r\nIhr Unternehmen in Übereinstimmung mit dem Sektor, in dem sie tätig werden wollen, zu gründen \r\nUm sie über die Entwicklungen in ihren Sektoren auf dem Laufenden zu halten, \r\nDafür zu sorgen, dass ihre Unternehmen von den Anreizen des Staates profitieren, \r\nihnen das Gefühl zu geben, zu Hause zu sein, indem wir mit ihnen in der Sprache kommunizieren, die sie sprechen.",
        our_mission_highlight: "ŞİN Mali Müşavirlik hat es sich zur Aufgabe gemacht, die umfassendsten Beratungsdienste in der Türkei anzubieten",
        our_vission_title: "Unsere Vision",
        our_vission: "Durch die Einführung des ŞiN (Şirket İşletim Normları) Qualitätsmanagementsystems in Unternehmen; \r\n\r\nSchaffung von Frieden in der Arbeitsumgebung durch System und Disziplin, \r\nArbeitnehmer zu haben, die sich um die Perfektion ihrer Arbeit bemühen \r\ndas Unternehmensvermögen durch kostenoptimales Arbeiten zu schützen \r\ndie Markteffizienz und -kompetenz des Unternehmens zu steigern und somit dazu beizutragen, dass das Unternehmen ein gutes Niveau erreicht.",
        our_vission_higlight: "ŞİN Mali Müşavirlik ist für seine Kunden eine wichtige Referenz für ihre Kontakte zu Dritten.",
        our_value_title: "Unsere Werte",
        our_value: "in unserer Tätigkeit Professionalität \r\nbei der Verfolgung der aktuellen Dynamik der Gesetzgebung \r\nin unseren Beziehungen mit unseren Kunden die Privatsphäre, \r\nin der Loyalität zu ethischen Regeln, \r\nDiese bilden das Rückgrat und die Qualitätspolitik von ŞiN Mali Müşavirlik.",
        our_value_highlight: "Denn wir wissen, dass das, was wir tun, uns einen Wert gibt."
    },

    Counselling: {
        councelling_title: "SIN BUCHHALTUNGSDIENSTLEISTUNGEN",
        counselling: "Unser Unternehmen ist eine Wirtschaftsprüfungsgesellschaft, die seit ihrer Gründung ihre Dienstleistungen für ausländische Unternehmen erbringt. \r\nSo bieten wir unseren Kunden unsere Dienste mit unserer Erfahrung von mehr als 10 Jahren. \r\nWir erbringen unsere Dienstleistungen für unsere Kunden im Bereich der Buchhaltung mit den nachstehenden Titeln.",
        counselling_highlight: "Dienstleistungen zur Unternehmensgründung",
        company_creating: "unseren potenziellen Kunden beim Eintritt in den türkischen Markt Informationen über die Rechtsvorschriften zu liefern, \r\nUnsere Kunden über die Sektoren zu informieren, an denen sie interessiert sind, \r\nIhr Unternehmen entsprechend den Erfordernissen des Sektors, in dem sie arbeiten wollen, zu gründen",
        company_selling_highlight: "Kauf von Unternehmensdienstleistungen",
        company_selling: "Für den Fall, dass unser potenzieller Kunde ein türkisches Unternehmen kaufen möchte, prüfen wir, ob es für Ausländer irgendwelche Beschränkungen für den Kauf dieser Art von Unternehmen gibt oder nicht, \r\nFalls es kein Hindernis gibt, die Geschichte des Unternehmens zu überprüfen, die Gesamtschulden und Forderungen des Unternehmens zu überprüfen, einen Bericht über den rechtlichen Status des Unternehmens im Rahmen des Gesetzes zu erstellen,",
        company_partnering_highlight: "Partner werden & aus der Partnerschaft eines Unternehmens ausscheiden",
        company_partnering: "Falls unser potenzieller Kunde eine Partnerschaft mit einem türkischen Unternehmen anstrebt, prüfen wir die rechtliche und finanzielle Situation des Unternehmens, verfolgen den Partnerschaftsprozess und schließen ihn ab \r\nFalls unser Kunde aus der Partnerschaft aussteigen möchte, stellen wir sicher, dass alle Verfahren eingehalten werden und verfolgen den Ausstieg aus der Partnerschaft und schließen ihn ab,",
        financial_adviser_highlight: "Finanzberatungsdienste",
        financial_adviser: "Beratung in Finanzfragen (Steuern, SSK, Anreize, Ausnahmen, Befreiungen), \r\nDie Gründung, Verschmelzung, Übertragung und Auflösung von Unternehmen vorzunehmen, \r\nDie Änderung der Gesellschaftsform sowie die Erhöhung und Herabsetzung des Gesellschaftskapitals, \r\nErstellung eines Durchführbarkeitsberichts im Rahmen der Investitionsberatung,",
        accounting_adviser_highlight: "Beratung bei der Buchhaltung",
        accounting_adviser: "Die Bücher der Unternehmen gemäß dem türkischen Steuergesetzbuch zu führen. \r\nDie rechtzeitige und ordnungsgemäße Einhaltung der gesetzlichen Aufzeichnungen zu gewährleisten \r\nUnsere Kunden über die Gesetzgebung zur sozialen Sicherheit zu informieren \r\nDie Erklärung im Rahmen des Arbeitsrechts abzugeben \r\nDie Erklärungen im Rahmen der Steuergesetzgebung abzugeben",
        finance_adviser_highlight: "Finanzen Beratungsleistungen",
        finance_adviser: "Vorfinanzierungen für Export und Import \r\n Zur Absicherung der Forderungen im Export, Eximbankkredite, Factoring und Forfaitierung\r\nDas Risiko durch die Instrumente Forward-, Sawp- und Future-Verträge zu steuern, \r\nEinholung einer Bankbürgschaft und einer Bürgschaftserklärung \r\nDie Inzestive zu nutzen und die Präferenz der Kreditaufnahme zu bestimmen \r\nDie Gewinn und Verlustrechnung zu erstellen",
        goverment_incentive_adviser_highlight: "Staatliche Anreize Beratung",
        goverment_incentive_adviser: "Informationen über allgemeine Investitionsanreize zu liefern \r\nInformationen über regionale Investitionsanreize zu liefern \r\nBereitstellung von Informationen über Investitionsanreize für Großinvestitionen \r\nInformationen über strategische Investitionsanreize zu liefern",
        governance_adviser_highlight: "Audit-Beratung",
        governance_adviser: "Im Rahmen der allgemein anerkannten Grundsätze der Rechnungslegung und der Steuergesetzgebung; \r\nDurchführung der S.W.O.T.-Analyse \r\nAufbau eines internen Kontrollsystems \r\nDurchführung von Betriebs- und Konformitätsprüfungen \r\nÜberprüfung der Organisationsstruktur und der Arbeitsablaufdiagramme des Unternehmens, \r\nMessung der Leistungsfähigkeit der Abteilungen und des Talents der Mitarbeiter \r\nErstellung der ständigen und aktuellen Akten des Unternehmens",
        import_export_highlight: "Beratung für Export und Import",
        import_export_adviser: "Unser Buchhalter hat viele Seminare zu diesem Thema gehalten und auch die Eigentümer und Mitarbeiter des Unternehmens geschult. \r\nVier Hauptbereiche, die man im Außenhandel beherrschen sollte: Handwerkliches Wissen, Fremdsprache, Marketing und betriebliche Abläufe. \r\nOperativer Prozess: Erstellung der Arbeitsablaufdiagramme im Außenhandel, Erstellung der Infrastruktur der Dokumente und Festlegung der Liefer- und Zahlungsbedingungen, \r\nMarketing: Festlegung der Werbung und der Verkaufsstrategie. Vorbereitung der Recherchen am Schreibtisch und vor Ort \r\nStaatliche Anreize: Anreize am Arbeitsplatz, in Geschäften, Büros und Depots, Anreize für Werbung und Verkaufsförderung, Anreize für Marketingforschung, Markenregister und Turquality, \r\nUnterstützung von Messen: Staatliche Anreize für individuelle und nationale Messen und virtuelle Messen, \r\nVerträge mit Agenturen oder Vertriebshändlern: Bestimmung der Agentur oder des Vertriebshändlers auf dem Zielmarkt und Abschluss des Vertrags, \r\nUnsere Lösungspartner: Um alle Außenhandelsprozesse problemlos abwickeln zu können, stehen Ihnen unsere Zollagenturen sowie unser Speditionsnetzwerk zur Verfügung."
    },
    
    Counselling_Other: {
        counselling_Other_highlight: "SONSTIGE DIENSTLEISTUNGEN", 
        counselling_Other: "Wir wissen genau, was ein ausländischer Investor braucht, sobald er in die Türkei kommt, und wir bieten ihm die notwendige Unterstützung in diesen Bereichen.",
        realestate_highlight: "Immobilien-Beratungsdienste",
        realestate: "Unterstützung von Ausländern beim Kauf von Immobilien (Z.B. Haus, Arbeitsplatz, Büro, Grundstück, Fabrik usw.) zum Zwecke des Erwerbs der Staatsbürgerschaft oder als Investition, \r\nSuche nach Immobilien mit der gewünschten Besonderheit, \r\nÜberprüfung der Informationen über das Immobilienzertifikat, \r\nÜberprüfung des Eigentümers (reale Person oder Unternehmen) der Immobilie, wenn diese umstritten ist \r\nÜberprüfung ob eine Beschlagnahme, eine Hypotech oder ein Gerichtsverfahren vorliegt, \r\nPrüfung, ob die Immobilie über eine Nutzungsgenehmigung der zuständigen Gemeinde verfügt, \r\nÜberprüfung der Steuern und Gelder sowie der Maklerprovision, die zum Zeitpunkt des Immobilienkaufs zu zahlen sind, \r\nDurchführung des Kaufprozesses im Rahmen des geltenden Rechts, \r\nDurchführung des Einbürgerungsverfahrens für die Ausländer, \r\nKontrolle oder Ausarbeitung von Kauf- und Verkaufsverträgen in vertraglichen Vereinbarungen,",
        translation_service_highlight: "Sprachliche Übersetzungsdienste:",
        translation_service: "Der Buchhalter Mesut SIN ist ein vereidigter Übersetzer in den Sprachen Englisch, Französisch, Deutsch und Arabisch. Er bietet seine Übersetzungsdienste nur für seine Kunden an.",
        industrial_consultancy_highlight: "Sektorale Beratungsunternehmen",
        import_export_consultancy_highlight: "Export-Import-Beratung:",
        import_export_consultancy: "In diesem Sektor hat CPA Mesut SIN jahrelang gearbeitet, bevor er Buchhalter wurde. Er bietet Unterstützung in drei Hauptbereichen des Außenhandels: Gesetzgebung, operative Transaktionen und Marketing,",
        tourism_consultancy_highlight: "Tourismus-Beratung:",
        tourism_consultancy: "Aufbau der Infrastruktur von Unternehmen, die im Tourismussektor tätig sein werden, und Unterstützung bei der Erlangung der Zulassungen der Klassen A, B und C von TURSAB (Verband der türkischen Reisebüros),",
        education_foundation_highlight: "Beratung für Bildungseinrichtungen:",
        education_foundation: "Beschaffung aller Lizenzen für Unternehmen, die im Bildungssektor tätig werden wollen,",
        personal_permission_highlight: "Persönliche Berechtigungen",
        residince_permission_highlight: "Aufenthaltserlaubnis:",
        residince_permission: "Unseren Kunden eine Aufenthaltsgenehmigung für die von ihnen in der Türkei eröffneten Unternehmen zu verschaffen,",
        working_permission_highlight: "Arbeitsgenehmigung:",
        working_permission: "Erteilung von Arbeitserlaubnissen für unsere Kunden sowie für deren ausländische Arbeitnehmer,",
        citizenship_acquiring_highlight: "Erwerb der Staatsbürgerschaft:",
        citizenship_acquiring: "Weiterverfolgung und Abschluss des Erwerbs der Staatsbürgerschaft durch Immobilienkauf, Kapitaltransfer und Großinvestitionen,",
        rental_agreement_highlight: "Mietvertragsdienstleistungen",
        home_rent_contracts_highlight: "Mietvertragsdienstleistungen",
        home_rent_contracts: "Wir erstellen den Mietvertrag für ihre Wohnungen (in vier Sprachen), prüfen die vorbereiteten Verträge, um die Rechte unserer Kunden zu schützen, übersetzen sie und beglaubigen sie beim Notar.",
        office_rent_highlight: "Mietverträge für Büros",
        office_rent: "Wir entwerfen den Mietvertrag für ihre Büros (in vier Sprachen), prüfen die vorbereiteten Verträge, um die Rechte unserer Kunden zu schützen, übersetzen sie und beglaubigen sie beim Notar.",
        home_office_highlight: "Dienstleistungen im Home Office",
        home_office: "Wir unterstützen unsere Kunden in dem Sektor, in dem sie arbeiten werden, und helfen ihnen bei der Einrichtung von Home-Office-Infrastrukturen",
        virtual_office_highlight: "Virtuelle Bürovermietung",
        virtual_office: "Bereitstellung von virtuellen Bürodienstleistungen für unsere Kunden in den Bereichen, in denen sie arbeiten werden,",
        physical_office_highlight: "Physische Bürovermietung",
        physical_office: "Wir ermitteln zunächst den für unsere Kunden günstigsten Standort und helfen ihnen, einen Mietvertrag mit dem Einkaufszentrum oder dem Standortmanagement abzuschließen.",
        company_infrastructure_highlight: "Unternehmen Infrastrukturdienste",
        company_introduction_highlight: "Vorstellung des Unternehmens Dienstleistungen:",
        company_introduction: "Um die Einführung von Unternehmen zu erstellen; Website, Einführungsschreiben, Referenzschreiben, youtube-Kanal und andere Social-Media-Konten zu erstellen,",
        company_documentiation_highlight: "Dienstleistungen der Unternehmensdokumentation:",
        company_documentiation: "Um die Dokumentation von Unternehmen zu erstellen; Visitenkarte, Logo, Stempel, Briefpapier, Rechnung, elektronische Signatur,",
        quality_management_highlight: "SIN Qualitätsmanagement-System",
        quality_management: "Durch die Einrichtung des SIN-Qualitätsmanagementsystems zur Messung der Leistungsfähigkeit der Mitarbeiter und der Abteilungen in den Unternehmen,",
   
        import_export_highlight: "Beratung für Export und Import",
        import_export_adviser: "Unser Buchhalter hat viele Seminare zu diesem Thema gehalten und auch die Eigentümer und Mitarbeiter des Unternehmens geschult. \r\nVier Hauptbereiche, die man im Außenhandel beherrschen sollte: Handwerkliches Wissen, Fremdsprache, Marketing und betriebliche Abläufe.",
        operational_processes_title: "Operativer Prozess:",
        operational_processes_text: "Erstellung der Arbeitsablaufdiagramme im Außenhandel, Erstellung der Infrastruktur der Dokumente und Festlegung der Liefer- und Zahlungsbedingungen,",
        selling_processes_title: "Marketing:",
        selling_processes_text: "Festlegung der Werbung und der Verkaufsstrategie. Vorbereitung der Recherchen am Schreibtisch und vor Ort,",
        goverment_processes_title: "Staatliche Anreize:",
        goverment_processes_text: "Anreize am Arbeitsplatz, in Geschäften, Büros und Depots, Anreize für Werbung und Verkaufsförderung, Anreize für Marketingforschung, Markenregister und Turquality",
        fuar_processes_title: "Unterstützung von Messen:",
        fuar_processes_text: "Staatliche Anreize für individuelle und nationale Messen und virtuelle Messen,",
        distributor_processes_title: "Verträge mit Agenturen oder Vertriebshändlern:",
        distributor_processes_text: "Bestimmung der Agentur oder des Vertriebshändlers auf dem Zielmarkt und Abschluss des Vertrags,",
        solution_processes_title: "Unsere Lösungspartner:",
        solution_processes_text: "Um alle Außenhandelsprozesse problemlos abwickeln zu können, stehen Ihnen unsere Zollagenturen sowie unser Speditionsnetzwerk zur Verfügung.",

    },
    About_Me: {
        university: "Üniversite (1996 - 2000) ",
        university_data: "Uludağ Üniversitesi İktisadi İdari Bilimler Fakültesi ekonomi bölümü mezunudur.",
        foregin_languages: "Yabancı Diller (2000 - 2003)",
        foregin_languages_data: "Yabancı dillere olan ilgisi nedeniyle, mastırını üniversite sonrası gittiği Kanada’nın Montreal bulunan Education Canada College üniversitesinde 'İngilizce Öğretmenliği' üzerine tamamlayıp \r\nTESOL (Teacher of English to Speakers of Other Languages) diplomasını aldı. \r\nAkabinde Fransızca eğitimini yine aynı üniversitede CF (Competence en Français) diplomasını alarak tamamladı. \r\nAlmanca ve Arapça da bilen Mesut Şin, aynı zamanda İstanbul Dil Kulübü’nün kurucusudur. \r\n\r\nMesut ŞİN; İngilizce, Fransızca, Almanca ve Arapça dillerinde yeminli tercümanlık ruhsatına sahiptir.",
        external_export: "Dış Ticaret (2003 - 2010)",
        external_export_data: "Yurtdışı eğitimini tamamlayıp İstanbul’a döndükten sonra bir tekstil firmasında dış ticaret sorumlusu olarak çalışmaya başladı. \r\nİş yoğunluğu ve destekleyici eğitimler sayesinde dış ticaret alanında ilerledi. Sektöre özel ilgisi nedeniyle ihracat ve ithalatın her alanında (Mevzuat, Pazarlama ve Operasyonel işlemlerde) uzmanlaştı. \r\nDış Ticarette; akreditifler, devlet teşvikleri, masabaşı ve alan araştırmaları, fuar teşvik ve hazırlıkları, dış ticarette Acente ve Distribütörlük sözleşmeleri danışmanlık yaptığı konular arasındadır. \r\nAyrıca, çalıştığı şirketlerde ISO-9001 Kalite Yönetim Sistemi ki daha sonra daha sade bir kalite sistemi olan ŞİN (Şirket İşletim Normları) sistemleri kurmak suretiyle şirketlerin performans arttırıcı sistemlere kavuşmasına yardımcı oldu."
    },
    Refrences: {
        belgium: "Belgien",
        egypt: "Ägypten",
        canada: "Kanada",
        saudi: "Saudi-Arabien",
        sweden: "Schweden",
        algerie: "Algerien",
        cote: "Republic of cote d'ivoire",
        us: "USA",
        sengal: "Sengal",
        palestinian: "Palästina",
        sudan: "Sudan",
        bulgaria: "Bulgarien"
    },
    Bio: {
        title: "MESUT SIN",
        university_title: "Universität (1996 - 2000)",
        university: "Er absolvierte die Uludag Universität, Fakultät für Wirtschaft und Verwaltung, Fachbereich Wirtschaft.",
        foregin_languages_title: "Master-Abschluss (2000 - 2003)",
        foregin_languages: "Da er sich besonders für Sprachen interessierte, ging er nach Montreal, Kanada, um seine Englisch- und Französischkenntnisse zu verbessern. Er schloss sein Master-Studium am ECC (Education Canada Collage) als Englischlehrer ab und erwarb Zertifikate. In TESOL (Teacher of English to Speakers of Other Languages) und Französisch CF (Competence en Fançais).\r\nMesut SIN, der auch Arabisch und Deutsch spricht, ist der Gründer des Istanbul Language Club.\r\n\r\nMesut SIN ist vereidigter Übersetzer und Dolmetscher für die Sprachen Englisch, Französisch, Deutsch und Arabisch.",
        foregin_trade_title: "Außenhandel (2003 - 2010)",
        foregin_trade: "Nach seiner Rückkehr aus Kanada begann er als Außenhandelsbeauftragter in einem Textilunternehmen zu arbeiten. Dank der hohen Arbeitsdichte und der Teilnahme an verschiedenen Kursen und Seminaren gelang es ihm, im Außenhandel voranzukommen.\r\nDurch sein Interesse an diesem Sektor konnte er sich in den Bereichen Gesetzgebung, Marketing und den operativen Bereichen des Außenhandels profilieren.\r\nSeine besonderen Stärken sind: Akkreditive, ausländische Investitionsanreize, Recherchen vor Ort und am Schreibtisch, Messeunterstützung, Agentur- und Vertriebsverträge.\r\n\r\nDamit die Unternehmen, für die er arbeitet, zu besseren Institutionen werden, hilft er ihnen mit dem Qualitätsmanagementsystem ISO-9001, aus dem er das einfachere SIN-Qualitätsmanagementsystem abgeleitet hat, dabei, leistungssteigernde Systeme zu haben.",
        accountancy_title: "Public Accountancy (2010 - )",
        accountancy: "Er gründete sein Wirtschaftsprüfungsbüro im Jahr 2010. In der Gründungsphase zielte er auf ein Kundenportfolio ab, das aus lokalen Unternehmen besteht, die exportieren wollen.\r\nAufgrund der spontan eingetretenen Umstände und der Nachfrage aus dem Ausland begann er, Dienstleistungen für die in die Türkei kommenden ausländischen Investoren zu erbringen. Aufgrund des Mangels an Wirtschaftsprüfungsbüros, die Dienstleistungen in Fremdsprachen anbieten, begann er, nur mit ausländischen Investoren zu arbeiten, um die Marktlücke zu schließen.",
        istanbul_title: "Istanbul",
        istanbul: "Mesut SIN, who is an Istanbul lover, supports all the works about Istanbul with the philosophy that 'Istanbul is the center of the world'.\r\nIt's our duty to add value to Istanbul, as well as to add value to the companies of our clients.",
        certificates: "Diplome / Lizenzen / Zertifikate"
    },
    library:{
        first_text: "Mali Muşavir Mesut SIN döviz kurlarındaki oynaklığı açıklıyor\r\n\r\nMaster Mesut SIN explains about the volatility in exchange rates\r\n\r\nMaître Mesut SIN s'explique la volatilité des taux de change\r\n\r\nMesut SIN erklärt die Volatilität der Wechselkurse\r\n\r\nالتقلبات في أسعار الصرف Mesut SIN يشرح  "
    },
    Contact:{
        contact_title: "Kontakt",
        phone_number: "Rufnummer",
        address: "Adresse"
    },
    Headers: {
        main_page: "Startseite",
        about_us: "Über Uns",
        counselling: "Leistungen",
        counselling_other: "Sonstige Dienstleistungen",
        reffrences: "Verweise",
        library: "Bibliothek",
        activities: "Seminare",
        contac_us: "Kontakt"
    }

}

export default TRANSLATION_DE;