import { Divider } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import Header from '../Components/Header/Header';
import TextCard from '../Components/TextCard/TextCard';
import classes from './AboutMe.module.css'
import './Pages.css'

function Consulting() {

    const { t } = useTranslation('translation', { keyPrefix: 'Counselling' });
    const { i18n } = useTranslation();

    return (
        <div className={classes.AboutMe}>
            <Header />

            <div className={i18n.language === "ar" ? classes.ArabicRtl : ""}>
            <div className={classes.title}>
                <h1>{t("councelling_title")}</h1>
            </div>
            <div className={classes.TextSpace}>

                <div className={classes.AboutMe_Text}>


                    <TextCard>{t("counselling")}</TextCard>

                    <Divider orientation="left"><h2>{t("counselling_highlight")}</h2></Divider>
                    <TextCard>{t("company_creating")}</TextCard>

                    <Divider orientation="left"><h2>{t("company_selling_highlight")}</h2></Divider>
                    <TextCard>{t("company_selling")}</TextCard>

                    <Divider orientation="left"><h2>{t("company_partnering_highlight")}</h2></Divider>
                    <TextCard>{t("company_partnering")}</TextCard>

                    <Divider orientation="left"><h2>{t("financial_adviser_highlight")}</h2></Divider>
                    <TextCard>{t("financial_adviser")}</TextCard>

                    <Divider orientation="left"><h2>{t("accounting_adviser_highlight")}</h2></Divider>
                    <TextCard>{t("accounting_adviser")}</TextCard>

                    <Divider orientation="left"><h2>{t("finance_adviser_highlight")}</h2></Divider>
                    <TextCard>{t("finance_adviser")}</TextCard>

                    <Divider orientation="left"><h2>{t("goverment_incentive_adviser_highlight")}</h2></Divider>
                    <TextCard>{t("goverment_incentive_adviser")}</TextCard>

                    <Divider orientation="left"><h2>{t("governance_adviser_highlight")}</h2></Divider>
                    <TextCard>{t("governance_adviser")}</TextCard>

                </div>
            </div>
            </div>
            <Footer style={{ textAlign: 'center', bottom : 0, width: "100%", backgroundColor : "black", color : "white" }}></Footer>
        </div>);
}

export default Consulting;