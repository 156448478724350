const TRANSLATION_FR = {
    About_Us: {
        about_us_title: "A PROPOS DE NOUS",
        about_us: "ŞİN Mali Musavirlik est un Cabinet de Conseil Financier qui travaille uniquement avec des investisseurs étrangers venant en Turquie, depuis sa création. \r\nTous nos clients sont d'origine étrangère.",
        our_mission_title: "Notre Mission",
        our_mission: "Être avec nos clients dès leur arrivée en Turquie. \r\nEtablir leur entreprise en fonction du secteur dans lequel ils envisagent de travailler.\r\nLes tenir au courant de l'évolution de leur secteur.\r\nS’assurer que leurs entreprises bénéficient des incitations de l'Etat.\r\nCommuniquer avec eux dans la langue qu'ils parlent et les faire se sentir chez eux.",
        our_mission_highlight: "Nous, en tant que ŞİN Mali Musavirlik, fournissons la plus grande consultance de la Turquie.",
        our_vission_title: "Notre Vision",
        our_vission: "En établissent le système de SIN dans les sociétés, nous visons à: \r\n\r\nCréer un environnement de travail pacifique au moyen du système et de la discipline.\r\nAvoir des travailleurs qui se consacrent à la perfection dans leur travail.\r\nProtéger les actifs de l'entreprise en travaillant à un coût optimal.\r\nAccroître l'efficacité et la compétence de l'entreprise sur le marché et ainsi contribuer à ce que l'entreprise atteigne un bon niveau.",
        our_vission_higlight: "ŞİN Mali Musavirlik est une référence importante pour ses clients dans leurs contacts avec des tiers.",
        our_value_title: "Nos Values",
        our_value: "Dans nos activités, le professionnalisme, \r\nDans le respect de la législation actuelle, le dynamisme, \r\nDans nos relations avec nos clients, la confidentialité, \r\nDans le respect des règles éthiques, l'honnêteté, \r\nCes éléments constituent la base et la politique de qualité de SIN Mali Musavirlik.",
        our_value_highlight: "Parce que nous savons que ce qui nous donne de la valeur est ce que nous faisons."
    },

    Counselling: {
        councelling_title: "LES SERVICES DE SIN MALI MUSAVIRLIK",
        counselling: "Notre société est un cabinet comptable offrant ses services aux entreprises étrangères depuis sa création. Avec plus de 15 ans d'expérience, nous proposons des services spécialisés dans le domaine comptable. \r\n\r\nVoici la liste de services que nous offrons : ",
        counselling_highlight: "Formation de l'entreprise",
        company_creating: "Fournir des informations sur la législation à nos clients potentiels lors de leur entrée sur le marché turc. \r\nInformer nos clients sur les secteurs qui les intéressent. \r\nÉtablir leur entreprise en fonction des nécessités du secteur dans lequel ils souhaitent travailler.",
        company_selling_highlight: "Acheter une entreprise",
        company_selling: "Dans le cas où notre client potentiel souhaite acheter une société turque, nous vérifions s'il existe une restriction pour un étranger d'acheter cette société ou non d. S'il n'y a pas d'obstacle, nous vérifions l'historique de l'entreprise, la dette totale de l'entreprise et établissons un rapport sur le statut juridique de l'entreprise conformément la loi.",
        company_partnering_highlight: "Devenir partenaire et quitter le partenariat d'une entreprise",
        company_partnering: "Dans le cas où notre client potentiel souhaite être partenaire d'une entreprise turque, nous vérifions la situation juridique et financière de l'entreprise, suivons le processus de partenariat et finalisons la transaction. \r\nDans le cas où notre client souhaite quitter le partenariat, nous nous assurons que toutes les procédures sont appliquées et suivons le partenariat de sortie et finalisons la dissolution.",
        financial_adviser_highlight: "Conseil financier",
        financial_adviser: "Fournir des services de conseil dans les sujets financiers tels que la fiscalité, la Sécurité Sociale (SSK), les incitations, les exceptions et les exonérations. \r\nProcéder à la constitution, à la fusion, au transfert et à la dissolution de la société. \r\nAider à changer le type de société et augmenter ou diminuer son capital. \r\nRéaliser des rapports de faisabilité dans le cadre du conseil en investissement.",
        accounting_adviser_highlight: "Conseil comptable",
        accounting_adviser: "Tenir les livres des sociétés conformément au code fiscal turc. \r\nS’assurer que les enregistrements légaux sont conservés en temps et correctement. \r\nInformer nos clients sur la législation en matière de la sécurité sociale. \r\nSoumettre la déclaration conformément au droit du travail. \r\nSoumettre les déclarations dans le cadre de la Législation Fiscale.",
        finance_adviser_highlight: "Conseil financier",
        finance_adviser: "Proposer des services de préfinancement pour l'exportation et l'importation \r\nSécuriser les créances à l'exportation grâce à des crédits Eximbank, à l'affacturage et au forfaitage. \r\nGérer le risque à l'aide d'instruments tels que les contrats à terme, les swaps et les contrats à terme. \r\nObtenir des garanties bancaires et des lettres de garantie. \r\nObtenir des garanties bancaires et des lettres de garantie.",
        goverment_incentive_adviser_highlight: "Conseils sur les incitations gouvernementales",
        goverment_incentive_adviser: "Fournir des informations sur les incitations générales à l'investissement. \r\nFournir des informations sur les incitations régionales à l'investissement. \r\nFournir des informations sur les incitations à l'investissement à grande échelle. \r\nFournir des informations sur les incitations stratégiques à l'investissement.",
        governance_adviser_highlight: "Conseil d'Audit",
        governance_adviser: "Dans le cadre des principes comptables généralement reconnus et de la législation fiscale;\r\n\r\nEffectuer l'analyse S.W.O.T. \r\nMettre en place un système de contrôle interne. \r\nRéaliser des audits opérationnels et de conformité. \r\nVérifier la structure organisationnelle et les schémas de workflow de l'entreprise. \r\nMesurer les capacités des départements et le talent du personnel. \r\nCréer des fichiers permanents et courants de l'entreprise.",
        import_export_highlight: "Conseil Export Import",
        import_export_adviser: "Notre comptable a donné de nombreux séminaires sur ce sujet et a également formé les propriétaires et les travailleurs de l'entreprise. \r\nQuatre domaines principaux que l'on doit dominer dans le commerce extérieur ; connaissance de l'artisanat, langue étrangère, marketing et processus opérationnel. \r\nProcessus opérationnel : création des schémas de workflow en commerce extérieur, constitution de l'infrastructure des documents et détermination des délais de livraison et de paiement, \r\nIncitations gouvernementales : incitations sur le lieu de travail, les magasins, les bureaux et les dépôts, incitations publicitaires et promotionnelles, incitations à la recherche marketing, registre de marque et Turquality, \r\nSoutiens aux foires : incitations gouvernementales pour les foires individuelles et nationales et les foires virtuelles, \r\nContrats d'agence ou de distributeur : Déterminer l'agence ou le distributeur sur le marché cible et former l'accord, Nos Partenaires Solutions : Afin de mener à bien tous les processus de commerce extérieur sans aucun problème, vous pouvez bénéficier de nos Agences en Douane ainsi que de notre réseau de Transitaires."
    },
    Counselling_Other: {
        counselling_Other_highlight: "AUTRES SERVICES",
        counselling_Other: "Nous savons exactement de quoi un investisseur étranger peut avoir besoin dès son arrivée en Turquie, et nous lui apportons le soutien nécessaire dans ces domaines.",
        realestate_highlight: "Services de conseil en immobilier",
        realestate: "Aider les étrangers à acheter des biens immobiliers (maison, lieu de travail, bureau, terrain, usine…etc) dans le but d’acquérir la citoyenneté ou d’investir. \r\nTrouver un bien immobilier correspondant aux critères recherches parle client.\r\nVérification des informations sur le Certificat Immobilier.\r\nVérification de la propriété du bien immobilier (personne physique ou société) en cas de controverse.\r\nVérifier s’il y a des confiscations, des hypothèques ou s’il est soumis à une procédure judiciaire.\r\nVérifier si le bien immobilier a un permis d’occupation de la Municipalité concernée.\r\nVérifier les taxes et les fonds et la commission de courtage qui doivent être payés lors de l’achat du bien immobilier.\r\nVérifier si le processus d’achat est mené dans le cadre de la loi en vigueur.\r\nRéaliser le processus de Citoyenneté pour les étrangers qui souhaitent l’obtenir.\r\nVérifier ou rédiger les contrats d’achat et de vente conformément aux accords contractuels.",
        translation_service_highlight: "Services de traduction linguistique :",
        translation_service: "Le comptable Mesut SIN est un traducteur assermenté dans les langues Anglais, Français, Allemand et Arabe. Il offre ses services de traduction uniquement à ses clients.",
        industrial_consultancy_highlight: "Conseils sectoriels",
        import_export_consultancy_highlight: "Conseil Export-Import",
        import_export_consultancy: "C'est le secteur dans lequel CPA Mesut SIN a travaillé pendant des années avant d'être comptable. Il fournit un soutien dans trois domaines principaux du commerce extérieur tels que;",
        tourism_consultancy_highlight: "Conseil en tourisme",
        tourism_consultancy: "Constituer l'infrastructure des entreprises qui travailleront dans le secteur du tourisme et les aider à obtenir un cotam de classe A, B, C de TURSAB (Association des Agences de Voyages Turques),",
        education_foundation_highlight: "Conseils pour les établissements d'enseignement :",
        education_foundation: "Pour obtenir toutes les licences nécessaires pour les entreprises qui souhaitent opérer dans le secteur de l'éducation.",
        personal_permission_highlight: "Autorisations personnelles",
        residince_permission_highlight: "Permis de résidence:",
        residince_permission: "Fournir à nos clients un permis de séjour pour les entreprises qu'ils ouvrent en Turquie.",
        working_permission_highlight: "Permis de travail:",
        working_permission: "Fournir un permis de travail à nos clients ainsi qu'à leurs travailleurs étrangers.",
        citizenship_acquiring_highlight: "Acquérir la citoyenneté :",
        citizenship_acquiring: "Aider les entreprises qui souhaitent opérer dans le secteur de l'éducation, à obtenir toutes les licences nécessaires.",
        rental_agreement_highlight: "Services de contrat de bail",
        home_rent_contracts_highlight: "Contrats de location de maisons :",
        home_rent_contracts: "Nous rédigeons le contrat de location de leurs logements (en quatre langues), vérifions les contrats préparés afin de protéger les droits de nos clients, les traduisons et les faisons notarier chez le notaire.",
        office_rent_highlight: "Contrats de location de bureaux :",
        office_rent: "Nous rédigeons le contrat de location de leurs bureaux (en quatre langues), vérifions les contrats préparés afin de protéger les droits de nos clients, les traduisons et les faisons notarier chez le Notaire.",
        home_office_highlight: "Services de bureau à domicile:",
        home_office: "Nous accompagnons nos clients dans le secteur dans lequel ils vont travailler et nous les aidons dans les infrastructures de bureau à domicile.",
        virtual_office_highlight: "Services de bureau virtuel :",
        virtual_office: "Nous déterminons d'abord l'emplacement le plus pratique pour nos clients et les aidons à obtenir le bail avec le centre commercial ou la gestion du site.",
        physical_office_highlight: "Services de bureau physique:",
        physical_office: "Nous identifions les fournisseurs de services de bureau virtuel les plus appropriés en fonction du secteur d'activité de nos clients, et nous les aidons à faire le choix le plus adapté.",
        
        company_infrastructure_highlight: "Services d'infrastructure d'entreprise",
        company_introduction_highlight: "Services de présentation de l'entreprise :",
        company_introduction: "Afin de créer l'introduction d'entreprises à créer; site Web, lettre d'introduction, lettre de référence, chaîne youtube et autres comptes de médias sociaux créant,",
        company_documentiation_highlight: "Services de documentation de l'entreprise:",
        company_documentiation: "Afin de créer l'introduction d'entreprises en créant de sites web, de lettres d'introduction, de lettres de référence, de chaînes YouTube et d’autres comptes de médias sociaux.",
        quality_management_highlight: "Système de gestion de la qualité du SIN:",
        quality_management: "En mettant en place le système de gestion de la qualité SIN pour évaluer les compétences des travailleurs et des services dans les entreprises,",
    
        import_export_highlight: "Conseil Export Import",
        import_export_adviser: "Notre comptable a donné de nombreux séminaires sur ce sujet et a également formé les propriétaires et les travailleurs de l'entreprise.\r\nIl existe quatre domaines clés à maitriser dans le commerce extérieur ; la connaissance de l'artisanat, les langues étrangères, le marketing et les processus opérationnels.",
        operational_processes_title: "Processus opérationnel:",
        operational_processes_text: "Création des schémas de workflow en commerce extérieur, constitution de l'infrastructure des documents et détermination des délais de livraison et de paiement.",
        selling_processes_title: "Marketing:",
        selling_processes_text: "Détermination de la publicité et de la stratégie de vente. Préparer le bureau et les recherches de terrain.",
        goverment_processes_title: "Incitations gouvernementales:",
        goverment_processes_text: "Incitations sur le lieu de travail, les magasins, les bureaux et les dépôts, ainsi que sur les incitations publicitaires et promotionnelles, les incitations à la recherche marketing, registre de marque et Turquality.",
        fuar_processes_title: "Soutiens aux foires:",
        fuar_processes_text: "Incitations gouvernementales pour les foires individuelles, nationales et virtuelles,",
        distributor_processes_title: "Contrats d'agence ou de distributeur:",
        distributor_processes_text: "Déterminer l'agence ou le distributeur sur le marché cible et former l'accord.",
        solution_processes_title: "Nos Solutions Partenaires:",
        solution_processes_text: "Afin de mener à bien tous les processus du commerce extérieur sans aucun problème, vous pouvez bénéficier de nos Agences en Douane ainsi que de notre réseau de Transitaires.",
    },
    About_Me: {
        university: "Université (1996 - 2000)",
        university_data: "Il est diplômé de l'Université d'Uludag, faculté d'économie et des sciences administratives, département d'économie.",
        foregin_languages: "Maître (2000 - 2003)",
        foregin_languages_data: "Étant particulièrement intéressé par les langues, il s’est rendu à Montréal au Canada pour perfectionner ses connaissances de l'anglais et du français. \r\nIl a obtenu sa maîtrise à ECC (Education Canada Collage) en tant que professeur d'anglais et a obtenu des certificats en TESOL (Teacher of English to Speakers of Other Languages) et en Français CF (Compétence en Français).\r\nMesut SIN, qui parle également l'arabe et l'allemand, est le fondateur de « Istanbul Language Club ». Mesut SIN est traducteur et interprète assermenté dans les langues Anglais, Français, Allemand et Arabe.",
        external_export: "Commerce Extérieur (2003 - 2010)",
        external_export_data: "Après son retour du Canada, il a commencé à travailler dans une entreprise textile en tant que responsable du commerce extérieur. Grâce à la densité de travail et à la participation à plusieurs cours et séminaires, il a réussi à se démarquer dans le commerce extérieur.  Son intérêt pour ce secteur, l’a fait exceller dans les domaines de la législation, du marketing et des domaines opérationnels du commerce extérieur.\r\nIl se distingue particulièrement dans les domaines suivants : Lettres de crédit, incitations à l'investissement étranger, recherches documentaires et sur le terrain, supports équitables, accords d'agence et de distributeurs. \r\nEn plus, afin que les entreprises pour lesquelles il travaille deviennent de meilleures institutions avec le système de gestion de la qualité ISO - 9001, dont il a dérivé le système de gestion de la qualité SIN qui est plus simple, aide les entreprises à disposer de systèmes d'amélioration des performances."
    },
    Refrences: {
        belgium: "Belgique",
        egypt: "Egypte",
        canada: "Canada",
        saudi: "Arabie Saoudite",
        sweden: "Suède",
        algerie: "Algérie",
        cote: "Republic of cote d'ivoire",
        us: "USA",
        sengal: "Sengal",
        palestinian: "Palestinien",
        sudan: "Soudan",
        bulgaria: "Bulgarie"
    },
    Bio: {
        title: "MESUT SIN",
        university_title: "Université (1996 - 2000)",
        university: "Il est diplômé de l'Université d'Uludag, faculté d'économie et des sciences administratives, département d'économie.",
        foregin_languages_title: "Maître (2000 - 2003)",
        foregin_languages: "Étant particulièrement intéressé par les langues, il s’est rendu à Montréal au Canada pour perfectionner ses connaissances de l'anglais et du français. \r\nIl a obtenu sa maîtrise à ECC (Education Canada Collage) en tant que professeur d'anglais et a obtenu des certificats en TESOL (Teacher of English to Speakers of Other Languages) et en Français CF (Compétence en Français).\r\nMesut SIN, qui parle également l'arabe et l'allemand, est le fondateur de « Istanbul Language Club ». Mesut SIN est traducteur et interprète assermenté dans les langues Anglais, Français, Allemand et Arabe.",
        foregin_trade_title: "Commerce Extérieur (2003 - 2010)",
        foregin_trade: "Après son retour du Canada, il a commencé à travailler dans une entreprise textile en tant que responsable du commerce extérieur. Grâce à la densité de travail et à la participation à plusieurs cours et séminaires, il a réussi à se démarquer dans le commerce extérieur.  Son intérêt pour ce secteur, l’a fait exceller dans les domaines de la législation, du marketing et des domaines opérationnels du commerce extérieur.\r\nIl se distingue particulièrement dans les domaines suivants : Lettres de crédit, incitations à l'investissement étranger, recherches documentaires et sur le terrain, supports équitables, accords d'agence et de distributeurs. \r\nEn plus, afin que les entreprises pour lesquelles il travaille deviennent de meilleures institutions avec le système de gestion de la qualité ISO - 9001, dont il a dérivé le système de gestion de la qualité SIN qui est plus simple, aide les entreprises à disposer de systèmes d'amélioration des performances.",
        accountancy_title: "Expert – Comptable (2010 - )",
        accountancy: "Il a créé son cabinet d'expertise comptable en 2010. Dans la phase d'implantation, il s’est adressé au portefeuille de clients constitué d'entreprises locales désireuses d'exporter.\r\nEn raison des circonstances qui se sont produites spontanément et des demandes de l'étranger, il a commencé à offrir des services aux investisseurs étrangers venant en Turquie.\r\nEn raison du manque de bureaux de comptabilité qui offrent des services dans les langues étrangères, il a décidé de combler ce vide en travaillant uniquement avec des investisseurs étrangers.",
        istanbul_title: "Istanbul",
        istanbul: "Mesut SIN, who is an Istanbul lover, supports all the works about Istanbul with the philosophy that 'Istanbul is the center of the world'.\r\nIt's our duty to add value to Istanbul, as well as to add value to the companies of our clients.",
        certificates: "Diplômes / Licences / Certificats"
    },
    library:{
        first_text: "Mali Muşavir Mesut SIN döviz kurlarındaki oynaklığı açıklıyor\r\n\r\nMaster Mesut SIN explains about the volatility in exchange rates\r\n\r\nMaître Mesut SIN s'explique la volatilité des taux de change\r\n\r\nMesut SIN erklärt die Volatilität der Wechselkurse\r\n\r\nالتقلبات في أسعار الصرف Mesut SIN يشرح  "
    },
    Contact:{
        contact_title: "Contact",
        phone_number: "Numéro de téléphone",
        address: "Adresse"
    },
    Headers: {
        main_page: "Page d'accueil",
        about_us: "À Propos de Nous",
        counselling: "Consultance",
        counselling_other: "Autres Services",
        reffrences: "Les Références",
        library: "Bibliothèque",
        activities: "Séminaires",
        contac_us: "Contactez-nous"
    }
}

export default TRANSLATION_FR;