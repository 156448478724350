import Header from '../Components/Header/Header';
import classes from './Home.module.css'
import { Footer } from 'antd/lib/layout/layout';
import istanbul from "../Assets/logo/istanbul-61.jpg"
import { Col, Row } from 'antd';
import useWindowSize from '../useWindowSize';
import { useEffect, useRef, useState } from 'react';
import TextTransition, { presets } from "react-text-transition";
import "./Pages.css"
import Intro from '../Components/Intro';
import { DownOutlined } from '@ant-design/icons';

const Home = () => {

    const hero_text_headline = "Şin Mali Müşavirlik;"
    const hero_text = "- sadece yabancı ortaklı şirketlere hizmet veren bir Mali Müşavirlik Şirketidir.\r\n- is a Public Accountant Company to only serve to companies with foreign partners.\r\n- est une Cabin et de Conseil Financier qui donne ses services seulement aux sociétés ayan des partenaires étrangers.\r\n- ist eine Finanzberatungfirma whelche arbeitet mit die nur Unternehmen mit auslandischen Partnern betreut. \r\n- ist eine Finanzberatungfirma whelche arbeitet mit die nur Unternehmen mit auslandischen Partnern betreut."
    const size = useWindowSize()
    const [colSpan, setColSpan] = useState(24);
    const [index, setIndex] = useState(0);

    const divRef = useRef(null);

    const TEXTS = [
        "sadece yabancı ortaklı şirketlere hizmet veren bir Mali Müşavirlik Şirketidir.",
        "is a Public Accountant Company to only serve to companies with foreign partners.",
        "est une Cabinet de Conseil Financier qui donne ses services seulement aux sociétés ayan des partenaires étrangers.",
        "ist eine Finanzberatungfirma whelche arbeitet mit die nur Unternehmen mit auslandischen Partnern betreut.",
        "نحن نعمل فقط مع الشركات الأجنبية"
    ];

    useEffect(() => {
        console.log(size)
        if (size.width <= 1200) {
            setColSpan(24)
        }
        else {
            setColSpan(8)
        }
    }, [size])

    useEffect(() => {
        const intervalId = setInterval(() =>
            setIndex(index => index + 1),
            4000
        );
        return () => clearTimeout(intervalId);
    }, []);


    return (
        <div className={classes.home}>
            <Header />

            <div className={classes.home_area}>
                <img src={istanbul} alt="Welcome Screen" />
                <div className={classes.hero} >
                    <div>
                        {size.width > 700 && <Intro />}
                        <p className={classes.hero_headline}>{hero_text_headline}</p>
                        <TextTransition springConfig={presets.wobbly} className={classes.hero_text}>
                            {TEXTS[index % TEXTS.length]}
                        </TextTransition>
                        {false && <p className={classes.hero_text}>{hero_text}</p>}
                    </div>
                </div>
                <div className={classes.bottom_page_button_container}>
                    <DownOutlined onClick={() => {divRef.current.scrollIntoView({ behavior: 'smooth' });}} className={classes.bottom_page_button}/>
                </div>
            </div>
            <div ref={divRef} className={classes.video}>

                <Row justify="space-around" gutter={[16, 16]} className={classes.video_space}>
                    <Col span={colSpan}>
                        <iframe src="https://www.youtube.com/embed/6vibNrclPLY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>

                    <Col span={colSpan}>
                        <iframe src="https://www.youtube.com/embed/ZV23VP2u6Ng" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>

                    <Col span={colSpan}>
                        <iframe src="https://www.youtube.com/embed/adw2Eu1Nt-U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                </Row>
            </div>
            <Footer style={{ textAlign: 'center', bottom: 0, width: "100%", backgroundColor: "black", color: "white" }}></Footer>
        </div>
    );
}

export default Home;