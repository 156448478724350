import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import TRANSLATION_EN from "./Assets/translations/en";
import TRANSLATION_TR from "./Assets/translations/tr";
import TRANSLATION_FR from "./Assets/translations/fr";
import TRANSLATION_DE from "./Assets/translations/de";
import TRANSLATION_AR from "./Assets/translations/ar";



// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  tr: {
    translation: TRANSLATION_TR
  },
  en: {
    translation: TRANSLATION_EN
  },
  fr: {
    translation: TRANSLATION_FR
  },
  de:{
    translation: TRANSLATION_DE
  },
  ar:{
    translation: TRANSLATION_AR
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .init({
    resources,
    lng: localStorage.getItem('selected_lang') || "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;