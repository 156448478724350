import "../Pages/Pages.css"

function Intro() {

    return (
        <div className='desc'>
            <p className='desc_start'>{`To start a new life { `}</p>
            <div className='desc_text'>
                <p>open your company</p>
                <p>buy your real estate</p>
            </div>
            <p className='desc_start'>{` } in Türkiye`}</p>
        </div>
    );
}

export default Intro;