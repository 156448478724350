const TRANSLATION_EN = {
    About_Us: {
        about_us_title: "About Us",
        our_mission_title: "Our Mission",
        our_vission_title: "Our Vision",
        our_value_title: "Our Value",
        about_us: "ŞİN Mali Musavirlik is a Accountancy company that since its establishment only works with foreign investors coming to Turkey. \r\nAll of our clients are of foreign origin.",
        our_mission: "to be with our client from the very moment they come to Turkey, \r\nto establishe their company in accordance with the sector they plan to work, \r\nto keep them update about the developments in their secteurs, \r\nto make sure their companies benefit from the incentives of the State,\r\nto make them feel at home through communicating with them with the language that they speak.",
        our_mission_highlight: "ŞİN Mali Müşavirlik’s mission is to provide the most comprehensive advisory services in Turkey.",
        our_vission: "By establishing the ŞiN (Şirket İşletim Normları) Quality Management Systems in companies;\r\n\r\nto creat peace in working environment by means of system and discipline,\r\nto have workers that devote themselves to the perfectness of their jobs,\r\nto protect the corporate assets by working in optimum cost,\r\nto increase the company's market efficiency and competency and consequently contribute the company to reach good level.",
        our_vission_higlight: "ŞİN Mali Müşavirlik is an important reference for its clients in their third party contacts.",
        our_value: "in our activities professionalism,\r\nin following the current legislation dynamism,\r\nin our relations with our clients privacy,\r\nin ethical rules loyalty,\r\nThese constitute the backbone and quality policy of ŞiN Mali Müşavirlik.",
        our_value_highlight: "Because we know that things that give value to us is what we do."
    },

    Counselling: {
        councelling_title: "SIN ACCOUNTING SERVICES",
        counselling: "Our company is an Accounting Firm which gives its services to foreign companies since its establishment.\r\nThus, we give our services to our clients with our experience of more than 10 years.\r\nWe give our services to our clients in the accounting sector with the titles hereunder.",
        counselling_highlight: "Company formation services",
        company_creating: "to supply legislation information to our potential clients when entering into the Turkish Market,\r\nto inform our clients about the sectors that they are interested, \r\nto establish their company in accordance with the necessities of the sector that they want to work,",
        company_selling_highlight: "Buying company services",
        company_selling: "In case our potential client desire to buy a Turkish company, to check if there is any restriction for a foreigner to buy this kind of company or not,\r\nIn case there is no obstacle, to check the history of the company, to check out corporate total debts and claims, to make out report about the legal status of the company within the framework of the law,",
        company_partnering_highlight: "Become a partner & leave partnership of a company",
        company_partnering: "In case our potential client desire to be partner in a Turkish company; we check out the legal and financial standing if the company and follow up the partneship process and finalise,\r\nIn case our client wants to leave partnership, we make sure that all the procedures are being applied and follow up the leaving partnership and finalise,",
        financial_adviser_highlight: "Financial advisory services",
        financial_adviser: "to give consultancy services in the financial issues (Tax, SSK, incentive, exception, exemption),\r\nto carry out formation, merger, transfer and dissolution of company,\r\nto change of type of company and increase and decrease the capital of company, \r\nto make feasibility report within the framework of investment consultancy, ",
        accounting_adviser_highlight: "Accounting counseling services",
        accounting_adviser: "to keep the books of companies in accordance with the Turkish Tax Code. \r\nto insure the legal recods are being kept in time and properly \r\nto inform our clients about the Social Security Legislation \r\nto submit the declaration within the frame of Labour Law \r\nto submit the declarations within the framework of the Tax Legislation",
        finance_adviser_highlight: "Financial counseling services",
        finance_adviser: "Prefinancing for Export and İmport \r\nto secure the receivables in export, Eximbank credits, factoring and forfaiting \r\nFoto manage the risk by the instruments of Forward, Sawp, Future contracts, \r\nto obtain bank guaranty and letter of guaranty \r\nto utilise the incestives and determine the preference of borrowing, \r\nto form up the profit and loss account statement ",
        goverment_incentive_adviser_highlight: "Governmental incentives counseling",
        goverment_incentive_adviser: "to supply information about General investment incentives \r\nto supply information about regional investment incentives \r\nto supply information about Large-Scale investment incentives \r\nto supply information about Strategic investment incentives",
        governance_adviser_highlight: "Audit counseling",
        governance_adviser: "Within the framework of Generally accepted accounting principles and Tax legislation; \r\nPerforming the S.W.O.T analysis \r\nForming up internal control system \r\nMaking operational and conformity auditing \r\nChecking the organizational structure and workflow diagrams of the company,  \r\nMeasuring the capability of the departments and the talent of the staff  \r\nCreating the company's permanent and current files",
        },
    Counselling_Other: {
        counselling_Other_highlight: "OTHER SERVICES",
        counselling_Other: "We know exactly what a foreign investor may need as soon as they come to Turkey and we provide them necessary supports in those subjects.",
        realestate_highlight: "Real Estate Counselling Services",
        realestate: "Helping foreigners to buy real estates (such as house, workplace, office, land, factory…etc) for the purpose of either acquiring citizenship or investment, \r\nFinding real estate with the desired particularity,\r\nChecking the information over the Real Estate Certificate, \r\nChecking the ownership (real person or company) of the real estate if it is controversial, \r\nChecking if there is any confiscation, hypotech or it is subject to judicial process, \r\nChecking if the Real Estate has occupancy permit from the relevant Municiplity, \r\nChecking the taxes and funds and the brokerage commission that must be paid at the time of buying the real estate, \r\nConducting the buying process within the framework of the current law, \r\nCarrying out the citizenship process for the foreigners, \r\nChechking or drawing up the buying & selling contracts in contractual agreements,",
        translation_service_highlight: "Language Translation Services",
        translation_service: "The Accountant Mesut SIN is a Sworn Translator in the languages of English, French, German and Arabic. He gives his translation services to only his clients.",
        industrial_consultancy_highlight: "Sectoral Consultancies",
        import_export_consultancy_highlight: "Export-Import Consultancy:",
        import_export_consultancy: "This is the sector that CPA Mesut SIN worked for years before being Accountant. He provides support in three main areas of Foreign Trade such as; Legislation, Operational Transactions and Marketing,",
        tourism_consultancy_highlight: "Tourism Consultancy:",
        tourism_consultancy: "To form up the infrastructure of the companies that will work in the Tourism sector and help them obtain A, B, C class cotam from TURSAB (Association of Turkish Travel Agencies),",
        education_foundation_highlight: "Education Institutions Consultancy:",
        education_foundation: "To obtain all the licens for the companies that want to operate in the education sector,",
        personal_permission_highlight: "Personal Permissions",
        residince_permission_highlight: "Residence Permit:",
        residince_permission: "To provide our clients residence permit over the companies that they open in Turkey,",
        working_permission_highlight: "Work Permit:",
        working_permission: "To provide work permit for our clients as well as for their foreign workers,",
        citizenship_acquiring_highlight: "Acquiring Citizenship: ",
        citizenship_acquiring: "To follow up and finalize acquiring citizenship through real estate purchase, capital transfer and large-scale investment, ",
        rental_agreement_highlight: "Rental Agreement Services",
        home_rent_contracts_highlight: "Home Rent Contracts:",
        home_rent_contracts: "We draw up the rent contract for their homes (in four languages), to check the prepared contracts in order to protect the rights of our clients, translate them and notarise at Notary.",
        office_rent_highlight: "Office Rent Contracts:",
        office_rent: "We draw up the rent contract for their offices (in four languages), to check the prepared contracts in order to protect the rights of our clients, translate them and notarise at Notary.",
        home_office_highlight: "Home Office Services:",
        home_office: "To support our clients by the sector that they will work and help them in home office infrastructures,",
        virtual_office_highlight: "Virtual Office Rent Services:",
        virtual_office: "To provide virtual office services to our clients by the sectors that they will work,",
        physical_office_highlight: "Physical Office Rent Services:",
        physical_office: "We first determine the most convenient location for our clients and help them get the lease with the Mall or Site Managements",
        company_infrastructure_highlight: "Company Infrastructure Services",
        company_introduction_highlight: "Company Introduction Services:",
        company_introduction: "In order to create the introduction of companies to create; web site, introduction letter, reference letter, youtube channel and other social media accounts creating,",
        company_documentiation_highlight: "Company Documentation Services:",
        company_documentiation: "In order to create the documentation of companies to create; Business card, Logo, Stamp, Letterhead papers, Invoice, Electronic signature,",
        quality_management_highlight: "SIN Quality Management System:",
        quality_management: "By setting up the SIN Quality Management System to measure the capacity of the workers and the departments in companies,",

        import_export_highlight: "Export & Import Counseling",
        import_export_adviser: "Our Accountant has given a lot of seminars about this subect and also train the owners and workers of the company. \r\nFour main fields that one should dominate in foreign trade; craft knowledge, foreign language, marketing and operational process.",
        operational_processes_title: "Operational process:",
        operational_processes_text: "Creating the workflow diagrams in foreign trade, forming the infrastructue of the documents and determining terms of delivery and term of payment,",
        selling_processes_title: "Marketing:",
        selling_processes_text: "Determining the advertisement and the sales stratejy. Preparing the desk and field researches,",
        goverment_processes_title: "Governments incentives:",
        goverment_processes_text: "Workplace, shop-store, office and depot incentives, advertisement and promotion incentives, marketing research incentives, brand registery and Turquality",
        fuar_processes_title: "Fair supports:",
        fuar_processes_text: "Government incentives for individual and national based fairs and virtual fairs",
        distributor_processes_title: "Contracts of Agency or Distributor:",
        distributor_processes_text: "Determining the agency or distributor in the target market and forming up the agreement",
        solution_processes_title: "Our solution partners:",
        solution_processes_text: "In order to carry out all foreign trade process without any problems, you can benefit from our Custom Agencies as well as our Forwarders network.",

    },
    About_Me: {
        university: "University (1996 - 2000) ",
        university_data: "He graduted from Uludag University, faculty of economics and administrative sicences, departments of economy.",
        foregin_languages: "Foreign Languages (2000 - 2003)",
        foregin_languages_data: "Being particularly interested in languages, he went to Montreal, Canada to master his knowledge of both English and French. \r\nHe completed his Master’s degree at ECC (Education Canada Collage) as an English teacher and acquired certificates \r\nin TESOL (Teacher of English to Speakers of Other Languages) and French CF (Competence en Fançais) \r\nMesut SIN, who also speaks Arabic and German, is the founder of Istanbul Language Club. \r\n\r\nMesut SIN is certified sworn translator and interpreter in the languages of English, French, German and Arabic.",
        external_export: "Foreign Trade (2003 - 2010)",
        external_export_data: "After coming back from Canada, he started to work at a textile company as a foreign trade responsible. \r\nThanks to the density of work and participating in several courses and seminars he managed to get ahead in foreign trade. \r\n Through his interest in that sector, he exceled in terms of legislation, marketing and the operational areas of foreign trade. \r\n He is particularly excel at; Letters of credits, foreign investment incentives, desk and field researches, fair supports, Agency and Distributors agreements. \r\n\r\nBesides, in order that the companies that he workes for to turn into better institutions with the ISO - 9001 Quality Management System, which he derived SIN Quality Mangement System which is plainer,"
    },
    Refrences: {
        belgium: "Belgium",
        egypt: "Egypt",
        canada: "Canada",
        saudi: "Saudi Arabia",
        sweden: "Sweden",
        algerie: "Algerie",
        cote: "Republic of cote d'ivoire",
        us: "US",
        sengal: "Sengal",
        palestinian: "Palestinian",
        sudan: "Sudan",
        bulgaria: "Bulgaria"
    },
    Bio: {
        title: "MESUT SIN",
        university_title: "University (1996 - 2000)",
        university: "He graduted from Uludag University, faculty of economics and administrative sicences, departments of economy.",
        foregin_languages_title: "Master Degree (2000 - 2003)",
        foregin_languages: "Being particularly interested in languages, he went to Montreal, Canada to master his knowledge of both English and French.\r\nHe completed his Master’s degree at ECC (Education Canada Collage) as an English teacher and acquired certificates.\r\nIn TESOL (Teacher of English to Speakers of Other Languages) and French CF (Competence en Fançais).\r\nMesut SIN, who also speaks Arabic and German, is the founder of Istanbul Language Club.\r\n\r\nMesut SIN is certified sworn translator and interpreter in the languages of English, French, German and Arabic.",
        foregin_trade_title: "Foreign Trade (2003 - 2010)",
        foregin_trade: "After coming back from Canada, he started to work at a textile company as a foreign trade responsible.\r\nThanks to the density of work and participating in several courses and seminars he managed to get ahead in foreign trade.\r\nThrough his interest in that sector, he exceled in terms of legislation, marketing and the operational areas of foreign trade.\r\nHe is particularly excel at; Letters of credits, foreign investment incentives, desk and field researches, fair supports, Agency and Distributors agreements.\r\n\r\nBesides, in order that the companies that he workes for to turn into better institutions with the ISO - 9001 Quality Management System, which he derived SIN Quality Mangement System which is plainer, he helps the companies to have performance improving systems.",
        accountancy_title: "Public Accountancy (2010 - )",
        accountancy: "He set up his Public Accountant Office in 2010. In the establishment phase, he aimed at the customer portfolio that consist of local firms that want to export.\r\nBecause of the circumtances that apperred spontanously and the demans from abroad, he started to give services to the foreign investors coming to Turkey.\r\nBecause of lack of Public Accountant Offices that give services in foreing languages, in order to fill the gab in the market, he started to work with only foreign investors.",
        istanbul_title: "Istanbul",
        istanbul: "Mesut SIN, who is an Istanbul lover, supports all the works about Istanbul with the philosophy that 'Istanbul is the center of the world'. It's our duty to add value to Istanbul, as well as to add value to the companies of our clients.",
        certificates: "Diplomas / Licences / Certificates"
    },  
    library:{
        first_text: "Mali Muşavir Mesut SIN döviz kurlarındaki oynaklığı açıklıyor\r\n\r\nMaster Mesut SIN explains about the volatility in exchange rates\r\n\r\nMaître Mesut SIN s'explique la volatilité des taux de change\r\n\r\nMesut SIN erklärt die Volatilität der Wechselkurse\r\n\r\nالتقلبات في أسعار الصرف Mesut SIN يشرح  "
    },
    Contact:{
        contact_title: "Contact",
        phone_number: "Phone Number",
        address: "Address"
    },
    Headers: {
        main_page: "Main Page",
        about_us: "About Us",
        counselling: "Counselling",
        counselling_other: "Other Services",
        reffrences: "References",
        library: "Library",
        activities: "Seminars",
        contac_us: "Contact Us"
    }

}

export default TRANSLATION_EN;