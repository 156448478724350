const TRANSLATION_AR = {
    About_Us: {
        about_us_title: "معلومات عنا",
        about_us: "شركة شين للإستشارات المالية تعمل مع المستثمرين الأجانب منذ يوم تلأسيسها \r\nجميع عملائنا  أجانب 	",
        our_mission_title: "مهمتنا",
        our_mission: "أن نكون بجانب عملائنا منذ لحظة وصولهم إلى بلدنا \r\nتأسيس شركتهم وفقاً للمجال الذي يودون العمل فيه \r\nإبقائهم على علم بأخر التطورات في عالم الأعمال \r\nضمان استفادة الشركات التي يؤسسونها من الحوافز الحكومية \r\nجعلهم يشعرون وكأنهم في بلدهم من خلال التواجد معهم في كل جانب من جوانب الحياة التجارية من خلال تواصلنا معهم بلغتهم الأم.",
        our_mission_highlight: "مهمتنا الأساسية في شركة شين  للإستشارات المالية هي  تقديم الخدمة الأكثر شمولاً لعملائنا.",
        our_vission_title: "رؤيتنا",
        our_vission: "من خلال إنشاء نظام إدارة الجودة شين (معايير تشغيل الشركة) للشركات؛ \r\n\r\nضمان السلام في بيئة العمل عن طريق توفير النظام والإنضباط في مكان العمل، \r\nإضافة قيمة إلى الأشخاص الذين يكرسون أنفسهم لإتقان عملهم على أكمل وجه، \r\nحماية موارد الشركة من خلال العمل بالتكلفة المثلى، \r\nضمان وصول الشركة إلى هيكل يضيف قيمة للإقتصاد من خلال زيادة كفاءتها في السوق.",
        our_vission_higlight: "شين للإستشارات تعد مرجعًا مهمًا لعملائها في معاملاتهم مع الجهات الآخرى.",
        our_value_title: "قيمنا",
        our_value: "الإحترافية في تقديم الخدمة \r\nالديناميكية متابعة التشريعات الجديدة \r\nالسرية في علاقتنا مع العملاء \r\nالإلتزام بأخلاقيات العمل \r\nتشكل تلك القيم العمود الفقري وسياسة الجودة لشركة شين للاستشارات المالية.",
        our_value_highlight: "لأننا نؤمن أن الشيء التي يضيف قيمة لنا هو العمل الذي نقوم به."
    },

    Counselling: {
        councelling_title: "خدمات شين للإستشارات المالية",
        counselling: " شركة شين للإستشارات المالية تعمل مع المستثمرين الأجانب منذ يوم تلأسيسها \r\nلذلك نحن نخدم عملائنا بخبرتنا ومعرفتنا التي تمتد لأكثر من 10 سنوات. \r\nبصفتنا شركة شين للإستشارات المالية نساعد عملائنا الأجانب  في الموضوعات التالية بمجال المحاسبة.",
        counselling_highlight: "إجراءات تأسيس الشركة",
        company_creating: "تقديم الدعم بخصوص التشريعات  للمستثمرين الأجانب لدخول السوق التركي \r\nتذويد المستثمرين الأجانب بالمعلومات اللازمة حول القطاع الذي يهتمون به \r\nتمكينهم من تأسيس شركة تلبي متطلبات القطاع الذي يريدون العمل فيه",
        company_selling_highlight: "إجرائات شراء شركة",
        company_selling: " التحقق مما إذا كان الأجانب يمنعونهم من امتلاك شركة في القطاع المعني في حالة رغبة عميلنا الأجنبي في شراء شركة محلية \r\nلم يكن هناك عائق  البحث في تاريخ الشركة التي يرغبون في شرائها  وحساب إجمالي الدين إلى جانب فحص الوضع القانوني للشركة والإبلاغ عنه في إطار القوانين",
        company_partnering_highlight: "الشراكة أو فض الشراكة",
        company_partnering: " فحص وتقديم تقرير عن الوضع المالي والقانوني  للشركة ومتابعة وإنهاء معاملات الشراكة إذا كان العميل الأجنبي لدينا يرغب في أن يصبح شريكًا في شركة محلية \r\nالتأكد من تطبيق الإجراءات اللازمة لفض الشراكة ومتابعة إتمام هذه المعاملات، إذا رغب عميلنا في فض الشراكة",
        financial_adviser_highlight: "الإستشارات المالية",
        financial_adviser: "تقديم الإستشارات في الأمور المالية (الضرائب، الضمان الإجتماعي، الحوافز ، الإستثنائات، الإعفاء) \r\nالقيام بعمليات التأسيس والدمج والتحويل والحل للشركات. \r\nإجراء تغييرات في الشركات مثل زيادة وتخفيض رأس المال. \r\nإعداد دراسات الجدوى في إطار الإستشارات الاستثمارية.",
        accounting_adviser_highlight: "الإستشارات المحاسبية",
        accounting_adviser: "الاحتفاظ بدفاتر الشركات وفقًا لقانون الضرائب التركي \r\nالتأكد من أن السجلات القانونية والمعاملات المحاسبية تتم بشكل دقيق وكامل وفي الوقت المحدد. \r\nإخبار عملائنا بتشريعات الضمان الاجتماعي. \r\nإعداد الإقرارات ورفعها إلى الجهات الرسمية في إطار قانون العمل. \r\nإصدار وتصديق بيانات ضريبة القيمة المضافة والبيانات المختصرة بيانات ضريبة الدخل وبيانات ضرائب الشركات.",
        finance_adviser_highlight: "الاستشارات المالية",
        finance_adviser: "التمويل المسبق للاستيراد والتصدير \r\nتأمين المستحقات في التصدير وائتمانات بنك Eximbank والتخصيم والفرضية \r\nإدارة المخاطر الخاصة بــ Forward, Swap, Future contracts, Option \r\nالحصول على فواتير البنوك وخطابات الضمان واستخدامها  \r\nالاستفادة من حوافز الاستثمار وتحديد تفضيلات الاقتراض \r\nتجهيز جداول الدخل والمصروفات",
        goverment_incentive_adviser_highlight: "استشارات الحوافز الحكومية",
        goverment_incentive_adviser: "توفير معلومات عن تطبيق حوافز الاستثمار العامة \r\nتوفير معلومات عن تطبيق حوافز الإستثمار الإقليمية \r\nتوفير معلومات عن ممارسات حوافز الإستثمار ذات النطاق الواسع	\r\nتوفير معلومات عن تطبيقات تحفيز الإستثمار الاستراتيجي",
        governance_adviser_highlight: "الإستشارات الخاصة بأعمال التدقيق",
        governance_adviser: "في إطار مبادئ المحاسبة المقبولة بشكل عام  والتشريع الضريبي \r\nالقيام بالتحليل الرباعي للشركات \r\nإنشاء أنظمة المراقبة الداخلية \r\nإجراء عمليات التدقيق الخاصة بالنشاط والمطابقة \r\nفحص الهيكل التنظيمي للمؤسسة ومخططات سير العمل \r\nقياس قدرة الإدارات والكفاءة المهنية للموظفين \r\nإنشاء ملفات الشركة الدائمة والحالية.",
        import_export_highlight: "إستشارات التصدير والغستيراد",
        import_export_adviser: "قدم مستشارنا المالي العديد من الندوات حول التجارة الخارجية وقدم تدريبات خاصة لأصحاب الشركات والموظفين في هذا المجال \r\n هناك ٤ مجالات من الضروري إتقانها في التجارة الخارجية: المعرفة المهنية، اللغة الأجنبية، التسويق، العمليات التشغيلية. \r\nالمعاملات التشغيلية: إنشاء مخطط سير العمل في التجارة الخارجية ، إنشاء البنية التحتية للوثائق الرسمية، تحديد طرق التسليم والدفع \r\nالتسويق: تحديد استراتيجية الإعلان والمبيعات للمنتج، إعداد البنية التحتية للبحث المكتبي والميداني \r\nدعم فتح شركة، متجر، مكتب ومخزن، مكان عمل في الخارج ، دعم الإعلان والترويج ، دعم أبحاث السوق ، دعم تسجيل العلامة التجارية و Turquality \r\nدعم المعارض: حوافز حكومية للمشاركة الفردية والوطنية في المعارض الحقيقية والمعارض الافتراضية \r\nاتفاقيات الوكالة والتوزيع: تحديد أختيار الوكالة أو الموزع في السوق المستهدف وإعداد الاتفاقية\r\nشركاء الحل لدينا: يمكنك الإستفادة من إتصالاتنا مع الجمارك ووكلاء الشحن التي تعتبر ضرورية للتنفيذ السلس لمعاملات التجارة الخارجية الخاصة بك."
    },
    Counselling_Other: {
        counselling_Other_highlight: "خدماتنا الأخرى",
        counselling_Other: "نحن نعلم ما قد يحتاجه المستثمر الأجنبي منذ لحظة قدومه إلى تركيا ونوفر له الدعم اللازم في هذه الأمور.",
        realestate_highlight: "خدمات الاستشارات العقارية",
        realestate: " مساعدة الأجانب على شراء العقارات (مثل المنزل ، مكان العمل ، المكتب ، الأرض ، المصنع ... إلخ) لغرض الحصول على الجنسية أو الاستثمار\r\nالبحث عن العقارات بالخصوصية المرغوبة\r\nالتحقق من المعلومات الموجودة على الشهادة العقارية\r\nالتحقق من ملكية (شخص حقيقي أو شركة) للعقار إذا كانت مثيرة للجدل\r\nالتحقق مما إذا كان هناك أي مصادرة أو تكنولوجيا خاضعة لإجراءات قضائية\r\nالتحقق مما إذا كان العقار لديه تصريح إشغال من البلدية ذات الصلة\r\nالتحقق من الضرائب والأموال وعمولة السمسرة التي يجب دفعها وقت شراء العقار\r\nإجراء عملية الشراء في إطار القانون الحالي\r\nإجراء عملية الجنسية للأجانب\r\nفحص أو وضع عقود البيع والشراء في اتفاقيات تعاقدية",
        translation_service_highlight: "خدمات الترجمة",
        translation_service: "المستشار المالي مسعود شين هو مترجم محلف باللغات الإنجليزية والفرنسية والألمانية والعربية ويقدم خدمات الترجمة لعملائه فقط.",
        industrial_consultancy_highlight: "إستشارات حسب القطاع",
        import_export_consultancy_highlight: "إستشارات التصدير والإستيراد",
        import_export_consultancy: "نقدم الدعم للعملاء في جميع مجالات التجارة الخارجية والتشريع والمعاملات التشغيلية والتسويق، نظرًا لأنه القطاع الذي عمل فيه مستشارنا المالي لسنوات",
        tourism_consultancy_highlight: "إستشارات سياحية",
        tourism_consultancy: "نقوم بإنشاء البنية التحتية للشركات التي ستعمل كوكالات سفر في قطاع السياحة ونضمن حصولها على شهادة TURSAB بإعتبارها وكالة سفر سياحية من الدرجة A ، B ، C.",
        education_foundation_highlight: "إستشارات للمؤسسات التعليمية",
        education_foundation: "نقوم بإصدار كافة التصاريح اللازمة للمؤسسات التي ستعمل في مجال التعليم",
        personal_permission_highlight: "الإذونات الشخصية",
        residince_permission_highlight: "إصدار إذن إقامة",
        residince_permission: "ضمان حصول عملائنا على تصاريح الإقامة من خلال الشركات التي أنشأوها في تركيا.",
        working_permission_highlight: "إصدار إذن عمل",
        working_permission: "ضمان حصول عملائنا  و  أو موظفيهم الأجانب على تصاريح عمل.",
        citizenship_acquiring_highlight: "الحصول على الجنسية",
        citizenship_acquiring: "متابعة وإنهاء إجراءات الجنسية للأجانب الذين يرغبون في أن يصبحوا مواطنين من خلال شراء العقارات أو تحويل رأس المال أو الإستثمار واسع النطاق.",
        rental_agreement_highlight: "خدمات دعم عقود الإيجار",
        home_rent_contracts_highlight: "خدمات تأجير المنازل",
        home_rent_contracts: "من أجل حماية عملائنا نقوم بإعداد عقود إيجار العقارات المؤجرة لأغراض الإقامة بأربع لغات وفحص العقود الجاهزة وترجمتها وتوثيقها.",
        office_rent_highlight: "خدمات تأجير مكان العمل ",
        office_rent: "من أجل حماية حقوق عملائنا لإعداد عقود إيجار العقارات المؤجرة لغرض أن تصبح مكاناً للعمل بأربع لغات وفحص العقود الجاهزة وترجمتها وتوثيقها.",
        home_office_highlight: "خدمات",
        home_office: "شرح طلب home office لعملائنا وفقًا لمحتوى العمل الذي سيقومون به ووفقًا للقطاع الذي يعملون فيه وتقديم الدعم اللازم.",
        virtual_office_highlight: "خدمات المكتب الإفتراضي",
        virtual_office: "تحديد شركات المكاتب الافتراضية التي تقدم أنسب خدمات المكتب الافتراضي لعملائنا وفقًا للقطاع الذي يعملون فيه وللتأكد من أنهم يتخذون الخيار الأنسب.",
        physical_office_highlight: "خدمات تأجير مكتب",
        physical_office: "تحديد أنسب مكان لعملائنا وفقًا للقطاع الذي يعملون فيه واستئجار مكان العمل للقيام بذلك من خلال مؤسسات مثل مراكز التسوق وإدارة الموقع.",
        company_infrastructure_highlight: "إستشارات البنية التحتية للشركة",
        company_introduction_highlight: "خدمات الترويج للشركات",
        company_introduction: "من أجل إعداد البنية التحتية للترويج للشركة نقوم بإنشاء موقع ويب وبريد تعريف الشركة وخطاب مرجعي وتسجيل محرك البحث وقناة يوتيوب وحسابات التواصل الاجتماعي ",
        company_documentiation_highlight: "خدمات البنية التحتية للترويج للشركة:",
        company_documentiation: "إنشاء بطاقة عمل، بروشور ، شعار، ختم، ورقة الترويسة، توقيع إلكتروني للشركة",
        quality_management_highlight: "نظام ŞİN لإدارة الجودة",
        quality_management: "لقياس قدرات الموظفين والإدارات من خلال إنشاء نظام إدارة الجودة تحت اسم ŞİN  لإنشاء جدول الوصف الوظيفي ومخطط تدفق العمل ونظام الوكيل",
    
        import_export_highlight: "إستشارات التصدير والغستيراد",
        import_export_adviser: "قدم مستشارنا المالي العديد من الندوات حول التجارة الخارجية وقدم تدريبات خاصة لأصحاب الشركات والموظفين في هذا المجال. \r\n هناك ٤ مجالات من الضروري إتقانها في التجارة الخارجية: المعرفة المهنية، اللغة الأجنبية، التسويق، العمليات التشغيلية.",
        operational_processes_title: "المعاملات التشغيلية",
        operational_processes_text: "إنشاء مخطط سير العمل في التجارة الخارجية ، إنشاء البنية التحتية للوثائق الرسمية، تحديد طرق التسليم والدفع",
        selling_processes_title: "التسويق",
        selling_processes_text: "تحديد استراتيجية الإعلان والمبيعات للمنتج، إعداد البنية التحتية للبحث المكتبي والميداني",
        goverment_processes_title: "الحوافز الحكومية",
        goverment_processes_text: "دعم فتح شركة، متجر، مكتب ومخزن، مكان عمل في الخارج ، دعم الإعلان والترويج ، دعم أبحاث السوق ، دعم تسجيل العلامة التجارية و Turquality",
        fuar_processes_title: "دعم المعارض",
        fuar_processes_text: "حوافز حكومية للمشاركة الفردية والوطنية في المعارض الحقيقية والمعارض الافتراضية",
        distributor_processes_title: "اتفاقيات الوكالة والتوزيع",
        distributor_processes_text: "تحديد أختيار الوكالة أو الموزع في السوق المستهدف وإعداد الاتفاقية",
        solution_processes_title: "شركاء الحل لدينا",
        solution_processes_text: " يمكنك الإستفادة من إتصالاتنا مع الجمارك ووكلاء الشحن التي تعتبر ضرورية للتنفيذ السلس لمعاملات التجارة الخارجية الخاصة بك.",

    },
    About_Me: {
        university: "الجامعة (1996 - 2000) ",
        university_data: "تخرج في جامعة أولوداغ من كلية العلوم الاقتصادية والإدارية.",
        foregin_languages: "اللغات الأجنبية (2000 - 2003)",
        foregin_languages_data: "نظرًا لاهتمامه باللغات الأجنبية فقد أكمل درجة الماجستير في كلية التربية الكندية في مونتريال بكندا في مجال تدريس اللغة الإنجليزية.	  \r\nوحصل على دبلوم TESOL (مدرس اللغة الإنجليزية لمتحدثي اللغات الأخرى). \r\nأكمل بعد ذلك  تعليمه الفرنسي في نفس الجامعة بدبلوم CF (الكفاءة في اللغة الفرنسية).\r\nمسعود شين الذي يتحدث أيضًا الألمانية والعربية هو مؤسس نادي اسطنبول للغات. \r\n\r\nمسعود شين لديه رخصة مترجم محلف باللغات الإنجليزية والفرنسية والألمانية والعربية.",
        external_export: "التجارة الخارجية (2003 - 2010)",
        external_export_data: "بدأ العمل كمدير للتجارة الخارجية في شركة نسيج،  بعد أن أكمل تعليمه في الخارج وعاد إلى اسطنبول. \r\nتطور وتقدم في مجال التجارة الخارجية بفضل كثافة عمله وتدريباته الداعمة. ونظرًا لإهتمامه الخاص بذلك القطاع فقد تخصص في جميع مجالات التصدير والاستيراد (التشريع والتسويق والمعاملات التشغيلية).\r\nالموضوعات التي يهتم بها  في التجارة الخارجية هي خطابات الإعتماد والحوافز الحكومية والبحوث المكتبية والميدانية وحوافز الإستعدادات للمعارض وعقود الوكالة والتوزيع.\r\nبالإضافة إلى ذلك ساعد الشركات على تحقيق أنظمة تحسين الأداء من خلال إنشاء نظام إدارة الجودة ISO-9001  والذي أصبح فيما بعد نظام جودة أبسط حيث أسس أنظمة SİN (معايير تشغيل الشركة) في الشركات التي عمل معها."
    },
    Refrences: {
        belgium: "بلجيكا",
        egypt: "مصر",
        canada: "كندا",
        saudi: "المملكة العربية السعودية",
        sweden: "السويد",
        algerie: "الجزائر",
        cote: "ساحل العاج",
        us: "الولايات المتحدة الأمريكية",
        sengal: "سنغال",
        palestinian: "فلسطين",
        sudan: "السودان",
        bulgaria: "بلغاريا"
    },
    library:{
        first_text: "Mali Muşavir Mesut SIN döviz kurlarındaki oynaklığı açıklıyor\r\n\r\nMaster Mesut SIN explains about the volatility in exchange rates\r\n\r\nMaître Mesut SIN s'explique la volatilité des taux de change\r\n\r\nMesut SIN erklärt die Volatilität der Wechselkurse\r\n\r\nالتقلبات في أسعار الصرف Mesut SIN يشرح  "
    },
    Bio: {
        title: "MESUT SIN",
        university_title: "الجامعة (1996 - 2000)",
        university: "تخرج من جامعة أولوداغ، كلية الاقتصاد والعلوم الإدارية قسم الاقتصاد.",
        foregin_languages_title: "درجة الماجستير (2000 - 2003)",
        foregin_languages: "نظرًا لاهتمامه بشكل خاص باللغات، انتقل شين إلى مدينة مونتريال بكندا لإتقان معرفته باللغتين الإنجليزية والفرنسية. وهناك أكمل درجة الماجستير في ECC (كلية التعليم الكندية) كمدرس للغة الإنجليزية وحصل على الشهادات الاعتمادية. حيث حصل على شهادة TESOL (مدرس اللغة الإنجليزية كلغة اجنبية) وشهادة CF (شهادة الكفاءة في اللغة الفرنسية). مسعود سين الذي يتحدث أيضًا العربية والألمانية، هو مؤسس نادي اسطنبول للغات. كما أنه أيضاً مترجم محلف ومترجم فوري معتمد في اللغات الإنجليزية والفرنسية والألمانية والعربية.",
        foregin_trade_title: "التجارة الخارجية (2003 - 2010)",
        foregin_trade: "بعد عودته من كندا، بدأ العمل في شركة نسيج كمسؤول عن التجارة الخارجية. وبفضل ذلك المنصب إلى جانب المشاركة في العديد من الدورات والندوات تمكن من التطور في مجال التجارة الخارجية. ومن خلال اهتمامه بهذا القطاع، برع في مجال التشريع والتسويق والمجالات التشغيلية للتجارة الخارجية. حيث يتميز بشكل خاص في الاعتمادات المستندية وحوافز الاستثمار الأجنبي والأبحاث المكتبية والميدانية والدعم في المعارض إلى جانب اتفاقيات الوكالة والموزعين.علاوة على ذلك، ومن أجل أن تتحول الشركات التي يعمل بها إلى مؤسسات أفضل مع نظام إدارة الجودة ISO - 9001، والذي اشتق منه نظام إدارة الجودة SIN وهو أكثر وضوحًا وأسهل أستخداماً، فإنه يساعد الشركات على الحصول على أنظمة تحسين الأداء.",
        accountancy_title: "المحاسبة العامة (2010 – إلى الآن)",
        accountancy: "أنشأ مكتبه للمحاسبين العامين في عام 2010. وفي مرحلة التأسيس، كان يستهدف الشركات من السوق المحلي التي ترغب في التصدير. وبسبب الظروف التي طرأت بشكل عفوي والطلبات من الخارج، بدأ بتقديم الخدمات للمستثمرين الأجانب القادمين إلى تركيا. ونظرًا لعدم وجود مكاتب محاسبين عامة تقدم خدمات باللغات الأجنبية، ومن أجل سد الفجوة في السوق، بدأ العمل مع المستثمرين الأجانب حصراً.",
        istanbul_title: "Istanbul",
        istanbul: "Mesut SIN, who is an Istanbul lover, supports all the works about Istanbul with the philosophy that 'Istanbul is the center of the world'.\r\nIt's our duty to add value to Istanbul, as well as to add value to the companies of our clients.",
        certificates: "الدبلومات / التراخيص / الشهادات"
    },
    Contact:{
        contact_title: "Contact",
        phone_number: "Phone Number",
        address: "Address"
    },
    Headers: {
        main_page: "الرئيسية",
        about_us: "من نحن",
        counselling: "خدماتنا", // Mesut Şin:   مسعود شين
        counselling_other: "خدمات اخرى",
        reffrences: "عملاؤنا",
        library: "مكتبة",
        activities: "فعاليات",
        contac_us: "إتصل بنا"
    }

}

export default TRANSLATION_AR;