import { Col, Divider, Row } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useTranslation } from "react-i18next";
import Header from "../Components/Header/Header";
import TextCard from "../Components/TextCard/TextCard";
import classes from './AboutMe.module.css'
import image from "../Assets/Images/Mesut_SIN.JPG"
import './Pages.css'

import dış_ticaret_semineri_1 from "../Assets/Images/dis_ticaret_semineri_1.jpg"
import dış_ticaret_semineri_2 from "../Assets/Images/dis_ticaret_semineri_2.jpg"
import dış_ticaret_semineri_3 from "../Assets/Images/dis_ticaret_semineri_3.jpg"

import dil_semineri_1 from "../Assets/Images/dil_semineri_1.jpg"
import dil_semineri_2 from "../Assets/Images/dil_semineri_2.jpg"
import dil_semineri_3 from "../Assets/Images/dil_semineri_3.jpg"
import { useEffect, useState } from "react";
import useWindowSize from "../useWindowSize";

function Seminars() {

    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const { i18n } = useTranslation();
    const size = useWindowSize()
    const [imgSpan, setImgSpan] = useState(24)


    useEffect(() => {
        setImgSpan(spanCalculator(size.width))
    }, [size])

    function spanCalculator(width){
        return width < 800 ? 24 : 8
    }


    return (
        <div className={classes.AboutMe}>
            <Header />

            <div className={classes.title}>
                <h1>Seminars</h1>
            </div>

            <div className="TextSpace_Wider">

                <div className={classes.AboutMe_Text} id="arabic">
                    <div>
                        <div>
                            <Divider orientation="left" orientationMargin="0"><h2>Dış Ticaret Seminerleri / Foreign Trade Seminars / Séminaire de Commerce Extérieur  / Außenhandelsseminare / ندوات التجارة الخارجية </h2></Divider>

                            <Row justify="space-around" className="seminar_images">
                                <Col span = {imgSpan}><img src={dış_ticaret_semineri_1}/></Col>
                                <Col span = {imgSpan}><img src={dış_ticaret_semineri_2}/></Col>
                                <Col span = {imgSpan}><img src={dış_ticaret_semineri_3}/></Col>
                            </Row>
                        </div>

                        <div>
                            <Divider orientation="left" orientationMargin="0"><h2>Yabancı Dil Seminerleri / Foreign Language Seminars / Séminaire de Langue Étrangère/ Fremdsprachenseminare / ندوات اللغات الأجنبية </h2></Divider>

                            <Row justify="space-around" className="seminar_images">
                                <Col span = {imgSpan}><img src={dil_semineri_1}/></Col>
                                <Col span = {imgSpan}><img src={dil_semineri_2}/></Col>
                                <Col span = {imgSpan}><img src={dil_semineri_3}/></Col>
                            </Row>
                        </div>

                    </div>
                </div>
                <Divider />
            </div>

            <Footer style={{ textAlign: 'center', bottom: 0, width: "100%", backgroundColor: "black", color: "white" }}></Footer>
        </div>);
}

export default Seminars;