import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AboutMe from './Pages/AboutMe';
import "antd/dist/antd.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate 
} from "react-router-dom";
import Refrences from './Pages/Refrences';
import Home from './Pages/Home';
import MainPage from './Pages/MainPage';
import './i18n';
import Consulting from './Pages/Consulting';
import ConsultingOther from './Pages/Consulting_Other';
import Contact from './Pages/Contact';
import Bio from './Pages/Bio';
import Library from './Pages/Library';
import Seminars from './Pages/Seminars';

ReactDOM.render( //Seminars

  <BrowserRouter>
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/refrences" element={<Refrences />} />
      <Route path="/about" element={<AboutMe />} />
      <Route path="/consulting" element={<Consulting />} />
      <Route path="/library" element={<Library />} />
      <Route path="/consulting_other" element={<ConsultingOther />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/bio" element={<Bio />} />
      <Route path="/seminars" element={<Seminars />} />
      <Route path="*" element={<Navigate to="/home" />}/>
      
    </Routes>
  </BrowserRouter>
  ,
  document.getElementById('root')
);