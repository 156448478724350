import { Divider } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import Header from '../Components/Header/Header';
import TextCard from '../Components/TextCard/TextCard';
import classes from './AboutMe.module.css'
import './AboutMe.module.css'
import './Pages.css'

function AboutMe() {

    const { t } = useTranslation('translation', { keyPrefix: 'About_Us' });
    const { i18n } = useTranslation();

    return (
        <div className={classes.AboutMe}>
            <Header />

            <div className={i18n.language === "ar" ? classes.ArabicRtl : ""}>
                <div className={classes.TextSpace}>
                    <div className={classes.AboutMe_Text} id="arabic">


                        <Divider className={classes.divider} orientation="left" orientationMargin="0"><h2>{t("about_us_title")}</h2></Divider>
                        <TextCard >{t("about_us")}</TextCard>

                        <Divider className="divider" orientation="left" orientationMargin="0"><h2>{t("our_mission_title")}</h2></Divider>
                        <TextCard>{t("our_mission")}</TextCard>
                        <TextCard className="highlight">{t("our_mission_highlight")}</TextCard>

                        <Divider className="divider" orientation="left" orientationMargin="0"><h2>{t("our_vission_title")}</h2></Divider>
                        <TextCard>{t("our_vission")}</TextCard>
                        <TextCard className="highlight">{t("our_vission_higlight")}</TextCard>

                        <Divider className="divider" orientation="left" orientationMargin="100"><h2>{t("our_value_title")}</h2></Divider>
                        <TextCard>{t("our_value")}</TextCard>
                        <TextCard className="highlight">{t("our_value_highlight")}</TextCard>
                    </div>
                </div>
            </div>

            <Footer style={{ textAlign: 'center', bottom: 0, width: "100%", backgroundColor: "black", color: "white" }}></Footer>
        </div>);
}

export default AboutMe;